import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';
import buttonColors from '../../theme/buttonColors';

interface FeedbackPropTypes {
  type: string;
  closeModal?: () => void;
  show?: boolean;
  hide?: boolean;
}

const Modal = (feedback: FeedbackPropTypes) => {
  const Close = feedback.closeModal;
  const isShow = feedback.show;
  const isHide = feedback.hide;

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      backgroundColor: '#000000A3',
      position: 'fixed',
      padding: '0',
      minWidth: '0',
      width: '100%',
      height: '100%',
      bottom: '0',
      zIndex: -5,
      animationFillMode: 'forwards',
      opacity: 0,
      ...(isShow && { animation: `$fadeIn 300ms ${theme.transitions.easing.easeInOut}` }),
      ...(isHide && { animation: `$fadeOut 300ms ${theme.transitions.easing.easeInOut}` }),
    },
    modal: {
      backgroundColor: '#FFFFFF',
      position: 'fixed',
      minWidth: '0',
      width: '100%',
      height: '10em',
      bottom: '-100em',
      borderRadius: '8px 8px 0px 0px',
      zIndex: 100,
      padding: '32px 88px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      animationFillMode: 'forwards',
      ...(isShow && { animation: `$slideUp 500ms ${theme.transitions.easing.easeInOut}` }),
      ...(isHide && { animation: `$slideDown 500ms ${theme.transitions.easing.easeInOut}` }),
      [theme.breakpoints.down('sm')]: {
        height: '30%',
        padding: '20px 30px',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
      },
    },
    title: {
      fontFamily: 'Inter',
      fontWeight: 700,
      fontSize: '32px',
      margin: 0,
      marginBottom: '8px',
      color: '#292929',
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '24px',
      },
    },
    subtitle: {
      color: '#666666',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '16px',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
        fontSize: '16px',
      },
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    closeButton: {
      padding: '0px 24px',
      width: '136px',
      height: '48px',
      border: '1px solid #4985FD',
      borderRadius: '500px',
      color: '#4985FD',
      backgroundColor: 'transparent',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: '16px',
      cursor: 'pointer',
      transition: '0.2s',
      '&:hover': {
        backgroundColor: buttonColors.onHover,
        color: '#0A3A99',
        border: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    '@keyframes slideUp': {
      '0%': {
        bottom: '-100px',
      },
      '100%': {
        bottom: '0',
      },
    },
    '@keyframes slideDown': {
      '0%': {
        bottom: '0',
      },
      '100%': {
        bottom: '-100px',
      },
    },
    '@keyframes fadeIn': {
      '0%': {
        opacity: 0,
        zIndex: -5,
      },
      '100%': {
        opacity: 1,
        zIndex: 99,
      },
    },
    '@keyframes fadeOut': {
      '0%': {
        opacity: 1,
        zIndex: 99,
      },
      '100%': {
        opacity: 0,
        zIndex: -5,
      },
    },
  }));
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={Close}>
      <div className={classes.modal}>
        <div>
          <p className={classes.title}>Desculpe, tivemos algum problema.</p>
          {feedback.type === 'crowdedRoom' ? (
            <p className={classes.subtitle}>
              Ops...essa sala parece já estar lotada.Tente novamente ou procure outra sala.
            </p>
          ) : (
            <p className={classes.subtitle}>Estamos trabalhando para voltar logo. Tente novamente daqui a pouco. </p>
          )}
        </div>
        <div className={classes.buttonContainer}>
          <button className={classes.closeButton} onClick={Close}>
            ok, entendi
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
