import React, { useState, useRef, useEffect } from 'react';
import AboutDialog from '../../AboutDialog/AboutDialog';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import MoreIcon from '@material-ui/icons/MoreVert';
import {
  Button,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  Divider,
} from '@material-ui/core';

import { useAppState } from '../../../state';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '../../../icons/CheckIcon';
import VisualizationIcon from '../../../icons/visualizationIcon';
import FullScreenIcon from '../../../icons/FullScreenIcon';
import GaleryViewIcon from '../../../icons/GaleryViewIcon';
import ExitFullScreenIcon from '../../../icons/ExitFullScreenIcon';

export const IconContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

const useStyles = makeStyles((theme: Theme) => ({
  ScreenTypeButton: {
    background: 'black',
    position: 'relative',
    top: '12px',
    right: '88px',
    gap: '8px',
    padding: '0px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '500px',
    width: '155px',
    height: '48px',
    opacity: 0.72,
    zIndex: 15,
    transition: '0.3s',
    '&:hover': {
      background: 'rgba(0, 0, 0, 0.8)',
    },
    '&:focus': {
      outline: '#FF8A00 solid 2px',
    },
  },

  Icon: {
    marginLeft: '20px',
  },
  IconContainer: {
    marginRight: '18px',
  },
  subMenu: {
    marginTop: '68px',

    '& div': {
      background: '#292929',
      borderRadius: '8px',
    },
    '& ul': {
      background: 'transparent',
      boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
      width: '330px',
      padding: '20px',
      transition: '0.3s',
      borderRadius: '8px',
      border: '1px solid #E0E0E0',
      '& li': {
        background: 'transparent',
        transition: '0.3s',
      },
    },
  },
  menuItem: {
    height: '63px',
    background: 'black',
    color: 'white',
    margin: '0px',
    listStyle: 'none',
    '&:hover': {
      background: 'rgba(102, 102, 102, 0.32)',
      '& div': {
        background: 'transparent',
      },
    },
  },
  checkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    background: 'transparent !important',
  },
}));

export default function MenuScreen() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { setIsGalleryViewActive, isGalleryViewActive } = useAppState();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const classes = useStyles();

  function toggleFullscreen() {
    const element = document.querySelector<HTMLBodyElement>('body');

    if (!document.fullscreenElement) {
      setIsFullscreen(true);
      element?.requestFullscreen().catch(err => {
        alert(`Erro ao selecionar o modo tela cheia: ${err.message} (${err.name})`);
      });
    } else {
      setIsFullscreen(false);
      document.exitFullscreen();
    }
  }

  useEffect(() => {
    if (document.fullscreenElement) {
      setIsFullscreen(true);
    }
  }, [isGalleryViewActive]);

  return (
    <>
      <Button
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        onMouseDown={e => e.preventDefault()}
        ref={anchorRef}
        className={classes.ScreenTypeButton}
        data-cy-more-button
        style={{ color: '#ffffff', display: 'flex' }}
        disableFocusRipple={true}
      >
        {isMobile ? (
          <MoreIcon />
        ) : (
          <>
            <VisualizationIcon /> <p className={classes.Icon}>Visualizar</p>
          </>
        )}
      </Button>
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'top',
          horizontal: 'right',
        }}
        transitionDuration={600}
        className={classes.subMenu}
      >
        {/* <MenuItem onClick={() => setSettingsOpen(true)}>
          <IconContainer>
            <SettingsIcon />
          </IconContainer>
          <Typography variant="body1">Audio and Video Settings</Typography>
        </MenuItem> */}

        {/* {isSupported && (
          <MenuItem
          
            onClick={() => {
              setIsBackgroundSelectionOpen(true);
              setIsChatWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <IconContainer>
              <BackgroundIcon />
            </IconContainer>
            <Typography style={{color: 'white'}} variant="body1">Backgrounds</Typography>
          </MenuItem>
        )}

        {flipCameraSupported && (
          <MenuItem disabled={flipCameraDisabled} onClick={toggleFacingMode}>
            <IconContainer>
              <FlipCameraIcon />
            </IconContainer>
            <Typography variant="body1">Flip Camera</Typography>
          </MenuItem>
        )}

        {roomType !== 'peer-to-peer' && roomType !== 'go' && (
          <MenuItem
            disabled={isFetching}
            onClick={() => {
              setMenuOpen(false);
              if (isRecording) {
                updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
              } else {
                updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
              }
            }}
            data-cy-recording-button
          >
            <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
            <Typography variant="body1">{isRecording ? 'Stop' : 'Start'} Recording</Typography>
          </MenuItem>
        )}

       <MenuItem
          onClick={() => {
            VideoRoomMonitor.toggleMonitor();
            setMenuOpen(false);
          }}
        >
          <IconContainer>
            <SearchIcon style={{ fill: '#707578', width: '0.9em' }} />
          </IconContainer>
          <Typography variant="body1">Room Monitor</Typography>
        </MenuItem> */}
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            toggleFullscreen();
            setMenuOpen(false);
          }}
        >
          <IconContainer className={classes.IconContainer}>
            {!isFullscreen ? <FullScreenIcon /> : <ExitFullScreenIcon />}
          </IconContainer>
          <Typography style={{ color: 'white', fontWeight: 500 }} variant="body1">
            {!isFullscreen ? 'Tela Cheia' : 'Sair de tela cheia'}
          </Typography>
        </MenuItem>
        <Divider light style={{ backgroundColor: '#A3A3A3' }}></Divider>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            setIsGalleryViewActive(isGallery => !isGallery);
            setMenuOpen(false);
          }}
        >
          <IconContainer className={classes.IconContainer}>
            <GaleryViewIcon />
          </IconContainer>
          <Typography style={{ color: 'white', fontWeight: 500 }} variant="body1">
            Exibição galeria
          </Typography>
          {isGalleryViewActive && (
            <div className={classes.checkContainer}>
              <CheckIcon />
            </div>
          )}
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            setIsGalleryViewActive(isGallery => !isGallery);
            setMenuOpen(false);
          }}
        >
          <IconContainer className={classes.IconContainer}>
            <VisualizationIcon />
          </IconContainer>
          <Typography style={{ color: 'white', fontWeight: 500 }} variant="body1">
            Exibição palestrante
          </Typography>
          {!isGalleryViewActive && (
            <div className={classes.checkContainer}>
              <CheckIcon />
            </div>
          )}
        </MenuItem>

        {/* <MenuItem onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">About</Typography>
        </MenuItem> */}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
