import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import fontColors from '../../theme/fontColors';
import { Typography } from '@material-ui/core';
import TwitterIcon from '../../icons/TwitterIcon';
import InstagramIcon from '../../icons/InstagramIcon';
import FacebookIcon from '../../icons/FacebookIcon';
import YoutubeIcon from '../../icons/YoutubeIcon';
import LinkedInIcon from '../../icons/LinkedInIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '96px',

      padding: '0px 88px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px 24px',
        width: '100%',
        height: '141px',
        marginBottom: '24px',
        position: 'absolute',
        bottom: '0',
      },
    },
    content: {
      borderTop: '1px solid #E0E0E0',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    contentIcons: {
      borderTop: '1px solid #E0E0E0',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        borderTop: 'none',
        justifyContent: 'flex-start',
        height: '48px',
        gap: '8px',
      },
    },
    subText: {
      fontSize: '14px',
      fontWeight: 400,
      color: fontColors.subtitle,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        width: '100%',
        margin: '24px 0px',
      },
    },
    buttonSocialItem: {
      border: 'none',
      backgroundColor: 'transparent',
      padding: '0',
      marginLeft: '16px',
      marginRight: '16px',
      cursor: 'pointer',
    },
  })
);

const handleTwitter = () => {
  window.location.href = 'https://twitter.com/hsiriolibanes';
};

const handleInstagram = () => {
  window.location.href = 'https://www.instagram.com/hsiriolibanes/';
};

const handleFacebook = () => {
  window.location.href = 'https://www.facebook.com/HospitalSirioLibanes/';
};

const handleYoutube = () => {
  window.location.href = 'https://www.youtube.com/channel/UCYlaF2S8zWK7ntqw2vEP8qw';
};

const handleLinkedin = () => {
  window.location.href = 'https://www.linkedin.com/company/hospitalsiriolibanes/?originalSubdomain=br';
};

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Typography className={classes.subText}>© 2022 Hospital Sírio-Libanês</Typography>
      </div>
      <div className={classes.contentIcons}>
        <button onClick={() => handleTwitter()} className={classes.buttonSocialItem}>
          <TwitterIcon />
        </button>
        <button onClick={() => handleInstagram()} className={classes.buttonSocialItem}>
          <InstagramIcon />
        </button>
        <button onClick={() => handleFacebook()} className={classes.buttonSocialItem}>
          <FacebookIcon />
        </button>
        <button onClick={() => handleYoutube()} className={classes.buttonSocialItem}>
          <YoutubeIcon />
        </button>
        <button onClick={() => handleLinkedin()} className={classes.buttonSocialItem}>
          <LinkedInIcon />
        </button>
      </div>
    </div>
  );
}
