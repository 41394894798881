import React from 'react';

export default function TwitterIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.34803 19C15.7615 19 19.2704 13.2285 19.2704 8.22492C19.2704 8.06266 19.2671 7.8968 19.2605 7.73455C19.9431 7.19849 20.5321 6.53451 21 5.7738C20.3643 6.08095 19.6893 6.28154 18.9982 6.36873C19.7259 5.89504 20.2708 5.15088 20.5318 4.27422C19.8472 4.71481 19.0985 5.02561 18.3179 5.1933C17.7918 4.58636 17.0964 4.18449 16.3389 4.04983C15.5815 3.91517 14.8043 4.05522 14.1276 4.44831C13.4508 4.84141 12.9121 5.46567 12.5948 6.22457C12.2775 6.98347 12.1993 7.83474 12.3722 8.64678C10.9859 8.57123 9.62979 8.18018 8.39165 7.49897C7.15352 6.81776 6.06103 5.8616 5.18502 4.69247C4.73978 5.52608 4.60354 6.51251 4.80398 7.45128C5.00442 8.39005 5.5265 9.21072 6.26412 9.74649C5.71036 9.7274 5.16873 9.5655 4.68398 9.27416V9.32103C4.68349 10.1958 4.96199 11.0438 5.47214 11.7209C5.98229 12.3979 6.6926 12.8622 7.48234 13.0348C6.96937 13.1872 6.43099 13.2095 5.90885 13.0997C6.1317 13.8521 6.56528 14.5101 7.14909 14.982C7.73289 15.4538 8.43779 15.716 9.16541 15.7318C7.93013 16.7856 6.40421 17.3571 4.8334 17.3544C4.55483 17.3539 4.27653 17.3354 4 17.2989C5.59577 18.4106 7.45208 19.0011 9.34803 19Z"
        fill="#2C6EF2"
      />
    </svg>
  );
}
