/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Header from '../Header/Header';
import { useLocation } from 'react-router-dom';
import Illustration from '../../icons/svg/EndCallIllustration.svg';
import buttonColors from '../../theme/buttonColors';
import fontColors from '../../theme/fontColors';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import Footer from '../Footer/Footer';

interface TypeProps {
  type?: string;
}

const EndCallScreen = (types: TypeProps) => {
  const isMobile = window.innerWidth < 768;

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100%',
      },
    },
    containerFeedback: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        height: 'auto',
        padding: '0px 24px 0px 24px',
      },
    },
    containerText: {
      // display: 'flex',
      // flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        width: '100% !important',
        height: '100%',
        flexDirection: 'column',
      },
    },
    redirectButtons: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: '48px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
    },
    mainText: {
      fontSize: '32px',
      fontWeight: 700,
      width: '500px',
      color: fontColors.title,
      marginBottom: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
        marginTop: '40px',
        width: 'auto',
      },
    },
    subText: {
      fontSize: '20px',
      fontWeight: 400,
      color: fontColors.subtitle,
      width: '500px',
      marginBottom: '48px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        width: '100%',
        lineHeight: '30px',
        marginBottom: '104px',
      },
    },
    linkText: {
      fontSize: '16px',
      fontWeight: 400,
      color: buttonColors.primary,
      width: '500px',
      textDecoration: 'none',
      [theme.breakpoints.down('sm')]: {
        fontSize: '16px',
        width: '100%',
        paddingBottom: '16px',
      },
    },
    illustration: {
      width: '513px',
      height: '513px',
      ...(isMobile && {
        display: 'none',
      }),
    },

    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  }));

  const classes = useStyles();
  const location = useLocation();
  const params = location.pathname.split('/').filter(item => item);
  const roomType = params[1] === 'medico' ? 'Doctor' : 'Patient';

  let pathSirio = 'https://hospitalsiriolibanes.org.br/';
  let pathPatient = 'https://paciente.hsl.org.br/';
  let pathDoctor = 'https://portalmedico.hsl.org.br/conta/login';

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.containerFeedback}>
        {!isMobile && <img className={classes.illustration} src={Illustration} />}
        {roomType === 'Patient' ? (
          <div className={classes.containerText}>
            <Typography className={classes.mainText}>Consulta finalizada</Typography>
            <Typography className={classes.subText}>
              Em breve os documentos da consulta serão enviados para o e-mail cadastrado. Confira a sua caixa de
              entrada.
              <div className={classes.redirectButtons}>
                <a className={classes.linkText} href={pathSirio}>
                  Ir para site Sírio-Libanês
                </a>
                <a className={classes.linkText} href={pathPatient}>
                  Ir para Portal do Paciente
                </a>
              </div>
            </Typography>
          </div>
        ) : (
          <div className={classes.containerText}>
            <Typography className={classes.mainText}>
              Consulta finalizada
              <div className={classes.redirectButtons}>
                <a className={classes.linkText} href={pathSirio}>
                  Ir para site Sírio-Libanês
                </a>
                <a className={classes.linkText} href={pathDoctor}>
                  Ir para Portal do Médico
                </a>
              </div>
            </Typography>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default EndCallScreen;
