import React from 'react';

export default function FacebookIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5 13.2305C12.5001 13.2303 12.5002 13.2301 12.5003 13.2299L14.2585 13.1999L14.2721 13.1997L14.2856 13.1987C14.6401 13.1734 14.9507 12.9195 15.0289 12.5548L15.0305 12.5475L15.0318 12.5401L15.3818 10.6301L15.3819 10.6301L15.3832 10.6222C15.4639 10.138 15.0895 9.69 14.59 9.69V9.68995L14.583 9.69005L12.4586 9.71997C12.4558 9.71985 12.4547 9.71937 12.4545 9.71928L12.4544 9.71927C12.4539 9.71906 12.4532 9.71865 12.4524 9.71783C12.4515 9.71697 12.4508 9.71597 12.4504 9.71505C12.4502 9.71457 12.4499 9.71395 12.4498 9.71299L12.4133 7.47669L14.6151 7.43999C15.0587 7.43732 15.41 7.08453 15.41 6.64001H15.4101L15.4099 6.63168L15.37 4.23492C15.3673 3.79117 15.0145 3.44 14.57 3.44V3.43995L14.5626 3.44006L11.8626 3.48006L11.861 3.48009C9.92669 3.51505 8.38507 5.10111 8.4201 7.03903L8.4201 7.03909L8.47008 9.78909L8.47001 9.78909L8.47002 9.78938C8.46852 9.78972 8.46666 9.79002 8.46441 9.7902L7.27494 9.81001C6.83118 9.81268 6.48001 10.1655 6.48001 10.61H6.47995L6.48007 10.6179L6.51002 12.5148C6.51255 12.9587 6.86546 13.31 7.31 13.31V13.3101L7.31833 13.3099L8.51164 13.2901C8.51425 13.2902 8.51533 13.2906 8.51554 13.2907L8.51556 13.2907C8.51607 13.291 8.51679 13.2914 8.51763 13.2922C8.51852 13.293 8.5192 13.294 8.51961 13.295C8.51982 13.2954 8.52005 13.296 8.52019 13.297L8.61005 18.9879L8.61007 18.9889C8.61509 19.2706 8.38911 19.5 8.10999 19.5H5.81C4.08944 19.5 2.77634 18.9891 1.89377 18.1054C1.01114 17.2217 0.5 15.9059 0.5 14.18V5.81C0.5 4.08943 1.01093 2.77618 1.89355 1.89355C2.77618 1.01093 4.08943 0.5 5.81 0.5H14.19C15.9106 0.5 17.2238 1.01093 18.1064 1.89355C18.9891 2.77618 19.5 4.08943 19.5 5.81V14.19C19.5 15.9106 18.9891 17.2238 18.1064 18.1064C17.2238 18.9891 15.9106 19.5 14.19 19.5H13C12.7261 19.5 12.5 19.2739 12.5 19V13.2306C12.5 13.2306 12.5 13.2306 12.5 13.2305Z"
        fill="#2C6EF2"
        stroke="#2C6EF2"
      />
    </svg>
  );
}
