import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

// import Button from '@material-ui/core/Button';
import backgroundColors from '../../theme/backgroundColors';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
// import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import DividerIcon from '../../icons/png/DividerIcon.png';
// import FlexApi from 'twilio/lib/rest/FlexApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: backgroundColors.primary,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0',
      zIndex: 10,
      '& ToggleAudioButton': {
        margin: '0 12px 0 0',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    text: {
      color: '#ffffff',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();

  return (
    <>
      {/* {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )} */}
      <footer className={classes.container}>
        <Grid container justifyContent="space-between" alignItems="center" style={{ width: '100%' }}>
          <Hidden smDown>
            <Grid style={{ flex: 1, marginLeft: '88px' }}>
              <Typography
                variant="body1"
                style={{ color: '#ffffff', zIndex: 30, display: 'flex', alignItems: 'center' }}
              >
                {room!.name}
                <img alt="" style={{ width: '1px', height: '48px', marginLeft: '24px' }} src={DividerIcon} />
                <Hidden smDown>
                  <Menu />
                </Hidden>
              </Typography>
            </Grid>
          </Hidden>

          <Grid item>
            <Grid container justifyContent="center">
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              <EndCallButton />
              {/* {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />}
              
               */}
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <ToggleChatButton />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
