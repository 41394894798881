import React from 'react';

export default function CheckIcon() {
  return (
    <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.6971 0.783016C14.0931 1.16801 14.102 1.80111 13.717 2.19709L5.93899 10.1971C5.55751 10.5895 4.9315 10.6023 4.53424 10.2259L0.312239 6.22593C-0.0886841 5.84609 -0.105774 5.21316 0.274068 4.81224C0.653909 4.41131 1.28684 4.39422 1.68777 4.77407L5.19315 8.09513L12.283 0.802912C12.668 0.406932 13.3011 0.398024 13.6971 0.783016Z"
        fill="white"
      />
    </svg>
  );
}
