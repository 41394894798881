import React from 'react';

export default function BackIcon() {
  return (
    <svg width="11" height="19" viewBox="0 0 11 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.73668 0.802463C10.1274 1.19274 10.1278 1.82591 9.73756 2.21668L2.42378 9.53957L9.71712 16.8329C10.1076 17.2234 10.1076 17.8566 9.71712 18.2471C9.32659 18.6376 8.69343 18.6376 8.3029 18.2471L0.302903 10.2471C-0.0874493 9.85676 -0.0876464 9.22394 0.302463 8.83334L8.32246 0.803344C8.71274 0.412576 9.34591 0.412182 9.73668 0.802463Z"
        fill="white"
      />
    </svg>
  );
}
