import React from 'react';

export default function FullScreenIcon() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3 0.5C2.20435 0.5 1.44129 0.816067 0.878678 1.37868C0.316067 1.94129 0 2.70435 0 3.5V4.5C0 5.05228 0.447715 5.5 1 5.5C1.55228 5.5 2 5.05228 2 4.5V3.5C2 3.23478 2.10536 2.98043 2.29289 2.79289C2.48043 2.60536 2.73478 2.5 3 2.5H4C4.55228 2.5 5 2.05228 5 1.5C5 0.947715 4.55228 0.5 4 0.5H3ZM2 14.5C2 13.9477 1.55228 13.5 1 13.5C0.447715 13.5 0 13.9477 0 14.5V15.5C0 16.2956 0.316068 17.0587 0.878678 17.6213C1.44129 18.1839 2.20435 18.5 3 18.5H4C4.55228 18.5 5 18.0523 5 17.5C5 16.9477 4.55228 16.5 4 16.5H3C2.73478 16.5 2.48043 16.3946 2.29289 16.2071C2.10536 16.0196 2 15.7652 2 15.5V14.5ZM13 1.5C13 0.947715 13.4477 0.5 14 0.5H15C15.7956 0.5 16.5587 0.816067 17.1213 1.37868C17.6839 1.94129 18 2.70435 18 3.5V4.5C18 5.05228 17.5523 5.5 17 5.5C16.4477 5.5 16 5.05228 16 4.5V3.5C16 3.23478 15.8946 2.98043 15.7071 2.79289C15.5196 2.60536 15.2652 2.5 15 2.5H14C13.4477 2.5 13 2.05228 13 1.5ZM18 14.5C18 13.9477 17.5523 13.5 17 13.5C16.4477 13.5 16 13.9477 16 14.5V15.5C16 15.7652 15.8946 16.0196 15.7071 16.2071C15.5196 16.3946 15.2652 16.5 15 16.5H14C13.4477 16.5 13 16.9477 13 17.5C13 18.0523 13.4477 18.5 14 18.5H15C15.7956 18.5 16.5587 18.1839 17.1213 17.6213C17.6839 17.0587 18 16.2956 18 15.5V14.5Z"
        fill="white"
      />
    </svg>
  );
}
