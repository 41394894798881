import React from 'react';

export default function InstagramIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.49 5.80863V5.81V14.19C19.49 15.9106 18.9791 17.2238 18.0964 18.1064C17.2138 18.9891 15.9006 19.5 14.18 19.5H5.81C4.08945 19.5 2.77634 18.9891 1.89377 18.1054C1.01114 17.2217 0.5 15.9059 0.5 14.18V5.81C0.5 4.08944 1.01093 2.77618 1.89355 1.89355C2.77618 1.01093 4.08944 0.5 5.81 0.5H14.19C15.9107 0.5 17.2237 1.01097 18.105 1.89333C18.9861 2.77559 19.4947 4.08838 19.49 5.80863ZM16.0445 5.58165L16.064 5.56404L16.0816 5.54448C16.2081 5.40399 16.3091 5.24625 16.3815 5.07231C16.4528 4.90116 16.5 4.70585 16.5 4.5C16.5 4.29668 16.454 4.10364 16.3842 3.93401C16.3063 3.73429 16.198 3.58483 16.0816 3.45552L16.0543 3.42512L16.0223 3.39957C16.0124 3.39164 16.0066 3.38652 15.9967 3.37788C15.9884 3.37068 15.9774 3.36103 15.9588 3.34528C15.9322 3.32261 15.8858 3.28385 15.8289 3.24838C15.7491 3.19602 15.6635 3.15129 15.5721 3.11758C15.4824 3.07593 15.3867 3.04498 15.2794 3.02785C14.7908 2.92749 14.2774 3.09552 13.9364 3.43645L13.9271 3.44574L13.9184 3.45552C13.802 3.58483 13.6937 3.73429 13.6158 3.93401C13.546 4.10364 13.5 4.29668 13.5 4.5C13.5 4.70585 13.5472 4.90116 13.6185 5.07231C13.6909 5.24625 13.7919 5.40399 13.9184 5.54448L13.9271 5.55426L13.9364 5.56355C14.2237 5.85077 14.6024 6 15 6C15.2059 6 15.4012 5.95285 15.5723 5.88154C15.7462 5.80906 15.904 5.70809 16.0445 5.58165ZM5.62 10C5.62 12.4161 7.58386 14.38 10 14.38C12.4161 14.38 14.38 12.4161 14.38 10C14.38 7.58386 12.4161 5.62 10 5.62C7.58386 5.62 5.62 7.58386 5.62 10Z"
        fill="#2C6EF2"
        stroke="#2C6EF2"
      />
    </svg>
  );
}
