import React from 'react';

export default function LinkedInIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.34023 18.8387H8.84023V18.3387V9.74648V9.24648H8.34023H5.66836H5.16836V9.74648V18.3387V18.8387H5.66836H8.34023ZM15.1703 18.3387V18.8387H15.6703H18.3387V18.3387H18.8387V13.6242C18.8387 12.4566 18.7182 11.307 18.1741 10.444C17.5966 9.52806 16.6108 9.03203 15.1359 9.03203C14.2974 9.03203 13.6022 9.29259 13.0766 9.66852V9.24648H12.5766H10.0172H9.51719V9.74648V18.3387V18.8387H10.0172H12.682H13.182V18.3387V14.0918C13.182 13.5399 13.2387 13.1003 13.3993 12.8082C13.4731 12.6739 13.5661 12.5764 13.6885 12.5084C13.8138 12.4389 13.9996 12.384 14.2816 12.384C14.5446 12.384 14.697 12.4444 14.7907 12.5099C14.8855 12.5761 14.9636 12.6779 15.0251 12.8336C15.1595 13.174 15.1703 13.6424 15.1703 14.1621V18.3387ZM4.32891 3.5H19.6676C20.1393 3.5 20.5 3.86919 20.5 4.29727V19.7027C20.5 20.1282 20.1385 20.5 19.6676 20.5H4.32891C3.86066 20.5 3.5 20.1307 3.5 19.6992V4.29727C3.5 3.86749 3.85888 3.5 4.32891 3.5ZM4.95391 7.02891C4.95391 8.16008 5.87108 9.07578 7.0043 9.07578C8.13548 9.07578 9.05117 8.15509 9.05117 7.02891C9.05117 5.89847 8.13474 4.98203 7.0043 4.98203C5.87108 4.98203 4.95391 5.89773 4.95391 7.02891Z"
        fill="#2C6EF2"
        stroke="#2C6EF2"
      />
    </svg>
  );
}
