import React from 'react';
import clsx from 'clsx';
import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    messageInfoContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      height: '16px',
      fontSize: '12px',
      color: '#606B85',
    },
    messageInfoContainerLocal: {
      height: '16px',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      fontSize: '12px',
      color: '#606B85',
    },
    messageInfoContainerFirst: {
      display: 'none',
    },
  })
);

interface MessageInfoProps {
  author: string;
  dateCreated: string;
  isLocalParticipant: boolean;
  previousMessageAuthor: any;
}

export default function MessageInfo({
  author,
  dateCreated,
  isLocalParticipant,
  previousMessageAuthor,
}: MessageInfoProps) {
  const classes = useStyles();

  return (
    <div
      className={
        previousMessageAuthor === undefined
          ? classes.messageInfoContainerFirst
          : clsx(classes.messageInfoContainer, {
              [classes.messageInfoContainerLocal]: isLocalParticipant,
            })
      }
    ></div>
  );
}
