import React from 'react';
import { FormControl, MenuItem, Typography, Select, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';
import useDevices from '../../../hooks/useDevices/useDevices';
import buttonColors from '../../../theme/buttonColors';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '100%',
  },
  dropdown: {
    borderColor: '#292929',
    borderRadius: '8px',
    '& ul': {
      borderRadius: '8px',
      padding: '16px',
    },
    '&:hover': {
      backgroundColor: '#C5D7FB52',
      boxShadow: 'none',
    },

    [theme.breakpoints.down('xs')]: {
      color: 'white',
    },
  },
  item: {
    margin: '0 16px',
    padding: '24px',
    borderRadius: '8px',
    '&:first-child': {
      margin: '16px 16px 0 16px',
    },
    '&:last-child': {
      margin: '0 16px 16px 16px',
    },
    '&:hover': {
      backgroundColor: '#C5D7FB52',
      boxShadow: 'none',
    },
  },
}));

export default function AudioOutputList() {
  const classes = useStyles();
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

  return (
    <div className="inputSelect">
      {audioOutputDevices.length > 1 ? (
        <FormControl fullWidth>
          <p className={classes.subtitle}>Caixa de som</p>
          <Select
            className={classes.dropdown}
            onChange={e => setActiveSinkId(e.target.value as string)}
            value={activeSinkId}
            variant="outlined"
          >
            {audioOutputDevices.map(device => (
              <MenuItem className={classes.item} value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <p className={classes.subtitle}>Caixa de som</p>
          <Typography>{activeOutputLabel || 'System Default Audio Output'}</Typography>
        </>
      )}
    </div>
  );
}
