import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from 'react-router-dom';
import { useMediaQuery } from '@material-ui/core';
import { styled, Theme, makeStyles } from '@material-ui/core/styles';

import MenuMobile from './components/MenuBar copy/MenuMobile';
import MenuBar from './components/MenuBar/MenuBar';
import MobileTopMenuBar from './components/MobileTopMenuBar/MobileTopMenuBar';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import EndCallScreen from './components/EndCallScreen/EndCallScreen';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';

import { ParticipantProvider } from './components/ParticipantProvider';
import { VideoProvider } from './components/VideoProvider';
import { ChatProvider } from './components/ChatProvider';

import useConnectionOptions from './utils/useConnectionOptions/useConnectionOptions';
import useHeight from './hooks/useHeight/useHeight';
import { useAppState } from './state';
import useRoomState from './hooks/useRoomState/useRoomState';

import useVideoContext from './hooks/useVideoContext/useVideoContext';
import useChatContext from './hooks/useChatContext/useChatContext';

import LoadingIcon from './icons/Loading';

import Private from './private';
import ExpiredRoom from './components/ExpiredRoom/ExpiredRoom';
import ErrorRoom from './components/ErrorRoom/ErrorRoom';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `90px`, // Leave some space for the footer
  background: 'black',
  [theme.breakpoints.down('sm')]: {
    paddingBottom: `${theme.mobileFooterHeight + theme.mobileTopBarHeight}px`, // Leave some space for the mobile header and footer
  },
}));

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  containerConnecting: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& svg path': {
      fill: '#292929',
    },
  },
  loadMessage: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    color: '#292929',
    marginTop: '-88px',
  },
  load: {
    background: 'transparent',
    color: 'transparent',
    borderRadius: '500px',
    border: 'none',
    width: '56px',
    height: '56px',
    '& svg path': {
      fill: '#2C6EF2',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '210px',
      position: 'absolute',
      left: '43%',
      bottom: '34px',
    },
  },
}));

interface CredentialsState {
  tokenChat: string;
  tokenVideo: string;
}

interface LoadingProps {
  isLeaving: boolean;
}

const LoadingCall = (props: LoadingProps) => {
  const classes = useStyles();

  const { getAudioAndVideoTracks } = useVideoContext();

  useEffect(() => {
    getAudioAndVideoTracks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.containerConnecting}>
      <p className={classes.loadMessage}>{props.isLeaving ? 'Saindo da sala' : 'Carregando sala'}</p>
      <div className={classes.load}>
        <LoadingIcon />
      </div>
    </div>
  );
};

const Call = (props: any) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const roomState = useRoomState();
  const { getToken, postToken, credentials, setCredentials } = useAppState();
  const location = useLocation();
  const history = useHistory();

  const { connect: videoConnect, isAcquiredLocalTracks, isLeaving } = useVideoContext();
  const { connect: chatConnect } = useChatContext();

  const params = location.pathname.split('/').filter(item => item);
  const roomType = params[1] === 'medico' ? 'Doctor' : 'Patient';
  const roomId = params[2];

  const urlSearchParams = new URLSearchParams(location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());

  const passcode = searchParams.passcode;
  const token = searchParams.token;
  const channel = searchParams.channel;

  const message = 'connected';

  useEffect(() => {
    if (roomState === 'connected') {
      window.parent.postMessage(message, '*'); //connect message to iframes and others enviroments
    }
  }, [roomState]);

  useEffect(() => {
    if (isAcquiredLocalTracks) {
      // local tracks in place :)
      if (credentials.tokenChat && credentials.tokenVideo) {
        // login flow already saves token on state :)
        videoConnect(credentials.tokenVideo);
        chatConnect(credentials.tokenVideo);
      } else {
        // Doctor direct access from url :)
        console.log(roomId, passcode, roomType, token, channel);
        postToken(roomId, passcode, roomType, token, channel).then(Response => {
          if (Response.statusCode !== 13) {
            console.log('response do post - apptsx', Response);
            if (Response.statusCode === 148 || Response.statusCode === 6 || Response.errorType === 'ReferenceError') {
              history.push(`/salaexpirada`);
              return;
            }
          }

          getToken(roomId, passcode, roomType, token, channel).then(response => {
            if (response.errorType) {
              console.log('@@@@ 500');
              window.location.assign(
                `${window.origin}${location.pathname.replace('atendimento', 'login')}?roomError=true`
              );
            }
            if (Response.statusCode === 148 || Response.statusCode === 6 || Response.errorType === 'ReferenceError') {
              history.push(`/salaexpirada`);
              return;
            }

            if (response.statusCode === 401) {
              // redirect to login
              window.location.assign(
                `${window.origin}${location.pathname.replace('atendimento', 'login')}?channel=${channel}&token=${token}`
              );
            }
            const { tokenVideo, tokenChat } = response.data;

            setCredentials({
              tokenVideo, // jwt
              tokenChat, // chat
            });

            videoConnect(tokenVideo);
            chatConnect(tokenVideo);
          });
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAcquiredLocalTracks]);

  if (!isAcquiredLocalTracks || roomState !== 'connected') {
    return <LoadingCall isLeaving={isLeaving} />;
  }

  if (roomState === 'connected') {
    return (
      <Main>
        <ReconnectingNotification />
        <RecordingNotifications />
        <MobileTopMenuBar />
        <Room />

        {isMobile ? <MenuMobile /> : <MenuBar />}
      </Main>
    );
  }

  return null;
};

export default function App() {
  const [credentials, saveCredentials] = useState<CredentialsState>({
    tokenChat: '',
    tokenVideo: '',
  });
  const { setError } = useAppState();
  const connectionOptions = useConnectionOptions();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  return (
    <Container style={{ height }}>
      <VideoProvider options={connectionOptions} onError={setError}>
        <ParticipantProvider>
          <ChatProvider>
            <Router>
              <Switch>
                <Private
                  exact
                  path="/atendimento/:roomType/:roomId"
                  credentials={credentials}
                  saveCredentials={saveCredentials}
                  render={(props: any) => <Call {...props} credentials={credentials} />}
                />
                <Route
                  exact
                  path="/login/:roomType/:roomId"
                  render={() => <PreJoinScreens saveCredentials={saveCredentials} />}
                  // render={() => <EndCallScreen type='Patient'/>}
                />
                <Route exact path="/chamadaencerrada/:roomType/:roomId" render={() => <EndCallScreen />} />
                <Route exact path="/salaexpirada" render={() => <ExpiredRoom />} />
                <Route exact path="/errorsala" render={() => <ErrorRoom />} />
              </Switch>
            </Router>
          </ChatProvider>
        </ParticipantProvider>
      </VideoProvider>
    </Container>
  );
}
