import React from 'react';

export default function ExitFullScreenIcon() {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 5.5C2.8 5.5 3.6 5.2 4.1 4.6C4.6 4 5 3.3 5 2.5V1.5C5 0.9 4.6 0.5 4 0.5C3.4 0.5 3 0.9 3 1.5V2.5C3 2.8 2.9 3 2.7 3.2C2.5 3.4 2.3 3.5 2 3.5H1C0.4 3.5 0 3.9 0 4.5C0 5.1 0.4 5.5 1 5.5H2ZM3 17.5C3 18.1 3.4 18.5 4 18.5C4.6 18.5 5 18.1 5 17.5V16.5C5 15.7 4.7 14.9 4.1 14.4C3.6 13.8 2.8 13.5 2 13.5H1C0.4 13.5 0 13.9 0 14.5C0 15.1 0.4 15.5 1 15.5H2C2.3 15.5 2.5 15.6 2.7 15.8C2.9 16 3 16.2 3 16.5V17.5ZM18 4.5C18 5.1 17.6 5.5 17 5.5H16C15.2 5.5 14.4 5.2 13.9 4.6C13.3 4.1 13 3.3 13 2.5V1.5C13 0.9 13.4 0.5 14 0.5C14.6 0.5 15 0.9 15 1.5V2.5C15 2.8 15.1 3 15.3 3.2C15.5 3.4 15.7 3.5 16 3.5H17C17.6 3.5 18 3.9 18 4.5ZM13 17.5C13 18.1 13.4 18.5 14 18.5C14.6 18.5 15 18.1 15 17.5V16.5C15 16.2 15.1 16 15.3 15.8C15.5 15.6 15.7 15.5 16 15.5H17C17.6 15.5 18 15.1 18 14.5C18 13.9 17.6 13.5 17 13.5H16C15.2 13.5 14.4 13.8 13.9 14.4C13.3 14.9 13 15.7 13 16.5V17.5Z"
        fill="white"
      />
    </svg>
  );
}
