import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import buttonColors from '../../theme/buttonColors';
import backgroundColors from '../../theme/backgroundColors';

import ErrorMessageIcon from '../../icons/ErrorMessageIcon';
import CloseMessageIcon from '../../icons/CloseMessageIcon';

interface MessagePropTypes {
  close: () => void;
  show: boolean;
  hide: boolean;
}

export const PasswordNotification = (feedbacks: MessagePropTypes) => {
  const closeMessage = feedbacks.close;
  const open = feedbacks.show;
  const closed = feedbacks.hide;

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '16px',
      backgroundColor: '#FDC4C4',
      position: 'absolute',
      maxWidth: '312px',
      height: '120px',
      left: '40%',
      top: '-500px',
      boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
      borderRadius: '8px',
      zIndex: 99,
      animationFillMode: 'forwards',

      [theme.breakpoints.down('sm')]: {
        left: '20%',
        width: '78%',
      },
      ...(open && { animation: `$slideDown 500ms ${theme.transitions.easing.easeInOut}` }),
      ...(closed && {
        animation: `$slideUp 500ms ${theme.transitions.easing.easeInOut}`,
      }),
    },
    title: {
      color: '#710A0A',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      marginTop: '5.5px',
      marginBottom: '0px',
      fontSize: '16px',
    },
    subtitle: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      color: '#710A0A',
      marginTop: '8px',
      marginBottom: '0',
    },
    buttonEnter: {
      background: buttonColors.primary,
      color: backgroundColors.primaryBrand,
      marginTop: '100px',
      borderRadius: '500px',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      border: 'none',
      padding: '0px 32px',
      width: '111px',
      height: '56px',
      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        color: 'rgba(0, 0, 0, 0.32)',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '210px',
        width: '86%',
        height: '48px',
        position: 'absolute',
        left: '35px',
        bottom: '34px',
      },
    },
    containerText: {
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '16px',
    },
    closeButton: {
      position: 'relative',
      backgroundColor: 'transparent',
      height: '33px',
      width: '32px',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: '0.3s',
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: 'rgba(240, 86, 86, 0.32)',
      },
    },
    '@keyframes slideDown': {
      '0%': {
        top: '-100px',
      },
      '100%': {
        top: '24px',
      },
    },
    '@keyframes slideUp': {
      '0%': {
        top: '24px',
      },
      '100%': {
        top: '-500px',
      },
    },
  }));

  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ErrorMessageIcon />
      <div className={classes.containerText}>
        <p className={classes.title}>Sala não encontrada</p>
        <p className={classes.subtitle}>Confira se a senha foi digitada corretamente.</p>
      </div>
      <button className={classes.closeButton} onClick={closeMessage}>
        <CloseMessageIcon />
      </button>
    </div>
  );
};
