import React from 'react';
import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';
import { LocalAudioTrack } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Typography, Select, Grid, Theme } from '@material-ui/core';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '100%',
  },
  dropdown: {
    borderColor: '#292929',
    borderRadius: '8px',
    width: '100%',
    transition: '0.3s',
    '& ul': {
      borderRadius: '8px',
      padding: '16px',
    },
    '&:hover': {
      backgroundColor: '#C5D7FB52',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      color: 'white',
    },
  },
  item: {
    margin: '0 16px',
    padding: '24px',
    borderRadius: '8px',
    '&:first-child': {
      margin: '16px 16px 0 16px',
    },
    '&:last-child': {
      margin: '0 16px 16px 16px',
    },
    '&:hover': {
      backgroundColor: '#C5D7FB52',
      boxShadow: 'none',
    },
  },
}));

export default function AudioInputList() {
  const classes = useStyles();
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div>
      <p className={classes.subtitle}>Microfone</p>
      <Grid container alignItems="center" justifyContent="space-between">
        <div className="inputSelect">
          {audioInputDevices.length > 1 ? (
            <FormControl fullWidth>
              <Select
                className={classes.dropdown}
                onChange={e => replaceTrack(e.target.value as string)}
                value={localAudioInputDeviceId || ''}
                variant="outlined"
              >
                {audioInputDevices.map(device => (
                  <MenuItem className={classes.item} value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography>{localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}</Typography>
          )}
        </div>
      </Grid>
    </div>
  );
}
