import React from 'react';
import MessageInfo from './MessageInfo/MessageInfo';
import MessageListScrollContainer from './MessageListScrollContainer/MessageListScrollContainer';
import TextMessage from './TextMessage/TextMessage';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import MediaMessage from './MediaMessage/MediaMessage';

interface MessageListProps {
  messages: any[];
}

const getFormattedTime = (message?: any) =>
  message?.dateCreated?.toLocaleTimeString('pt-BR', { hour: 'numeric', minute: 'numeric' });

export default function MessageList({ messages }: MessageListProps) {
  const { room } = useVideoContext();
  const localParticipant = room!.localParticipant;

  return (
    <MessageListScrollContainer messages={messages}>
      {messages.map((message, idx) => {
        const time = getFormattedTime(message)!;
        const previousTime = getFormattedTime(messages[idx - 1]);

        // Display the MessageInfo component when the author or formatted timestamp differs from the previous message
        const shouldDisplayMessageInfo = time !== previousTime || message.author !== messages[idx - 1]?.author;

        const isLocalParticipant = localParticipant.identity === message.author;

        return (
          <React.Fragment key={message.sid}>
            {shouldDisplayMessageInfo && (
              <MessageInfo
                author={message.author!}
                previousMessageAuthor={messages[idx - 1]?.author}
                isLocalParticipant={isLocalParticipant}
                dateCreated={time}
              />
            )}

            {message.type === 'text' && (
              <TextMessage
                body={message.body!}
                dateCreated={time}
                time={message.dateCreated!}
                isLocalParticipant={isLocalParticipant}
              />
            )}

            {message.type === 'media' && <MediaMessage media={message.attachedMedia![0]} />}
          </React.Fragment>
        );
      })}
    </MessageListScrollContainer>
  );
}
