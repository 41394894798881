import React, { createContext, useContext, useReducer, useState } from 'react';
import { RecordingRules, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import { useLocalStorageState } from '../hooks/useLocalStorageState/useLocalStorageState';
import { User } from 'firebase/auth';

interface Credentials {
  tokenChat: string;
  tokenVideo: string;
}

interface ApiData {
  tokenVideo: string;
  tokenChat: string;
  roomName: string;
  channel: string;
  doctorId: string;
  patientId: string;
}

export interface StateContextType {
  credentials: Credentials;
  setCredentials: React.Dispatch<React.SetStateAction<Credentials>>;
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  postToken(
    room: string,
    passcode?: string,
    roomType?: string,
    token?: string,
    channel?: string
  ): Promise<{ room_type: RoomType; data: ApiData; statusCode?: number; message?: string; errorType?: string }>;
  getToken(
    room: string,
    passcode?: string,
    roomType?: string,
    token?: string,
    channel?: string
  ): Promise<{ room_type: RoomType; data: ApiData; statusCode?: number; message?: string; errorType?: string }>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  updateRecordingRules(room_sid: string, rules: RecordingRules): Promise<object>;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks from being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [credentials, setCredentials] = useState<Credentials>({ tokenChat: '', tokenVideo: '' });
  const [isFetching, setIsFetching] = useState(false);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState('gallery-view-active-key', true);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>();
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] = useLocalStorageState(
    'max-gallery-participants-key',
    6
  );

  let contextValue = {
    error,
    setError,
    credentials,
    setCredentials,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    roomType,
    isGalleryViewActive,
    setIsGalleryViewActive,
    maxGalleryViewParticipants,
    setMaxGalleryViewParticipants,
  } as StateContextType;

  contextValue = {
    ...contextValue,
    postToken: async (room, passcode, type, token, channel) => {
      const roomApi = `${process.env.REACT_APP_ROOM_API}`;
      const split = roomApi.split(';', 2);
      const endpoint = split[0];
      const apiKey = split[1];

      return await fetch(`${endpoint}/room`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-API-KEY': apiKey,
          roomid: room,
          channel: `${channel}`,
          type: `${type}`,
          token: `${token}`,
          passcode: `${passcode}`,
        },
      })
        .then(res => res.json())
        .catch(err => error);
    },
    getToken: async (room, passcode, type, token, channel) => {
      const roomApi = `${process.env.REACT_APP_ROOM_API}`;
      const split = roomApi.split(';', 2);
      const endpoint = split[0];
      const apiKey = split[1];

      return await fetch(`${endpoint}/room/${room}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'X-API-KEY': apiKey,
          channel: `${channel}`,
          passcode: `${passcode}`,
          type: `${type}`,
          token: `${token}`,
        },
      })
        .then(res => res.json())
        .catch(err => error);
    },
    updateRecordingRules: async (room_sid, rules) => {
      const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/recordingrules';

      return await fetch(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify({ room_sid, rules }),
        method: 'POST',
      })
        .then(async res => {
          const jsonResponse = await res.json();

          if (!res.ok) {
            const recordingError = new Error(
              jsonResponse.error?.message || 'There was an error updating recording rules'
            );
            recordingError.code = jsonResponse.error?.code;
            return Promise.reject(recordingError);
          }

          return jsonResponse;
        })
        .catch(err => setError(err));
    },
  };

  const postToken: StateContextType['postToken'] = (room, passcode, roomtype, token, channel) => {
    setIsFetching(true);
    return contextValue
      .postToken(room, passcode, roomtype, token, channel)
      .then(res => {
        setRoomType('group');
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const getToken: StateContextType['getToken'] = (room, passcode, roomtype, token, channel) => {
    setIsFetching(true);
    return contextValue
      .getToken(room, passcode, roomtype, token, channel)
      .then(res => {
        setRoomType('group');
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  const updateRecordingRules: StateContextType['updateRecordingRules'] = (room_sid, rules) => {
    setIsFetching(true);
    return contextValue
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return (
    <StateContext.Provider value={{ ...contextValue, getToken, postToken, updateRecordingRules }}>
      {props.children}
    </StateContext.Provider>
  );
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
