import React from 'react';

export default function VisualizationIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.00085 0C0.44857 0 0.000854492 0.447715 0.000854492 1V19C0.000854492 19.5523 0.44857 20 1.00085 20H8.00085C8.55314 20 9.00085 19.5523 9.00085 19C9.00085 19 9 10.5 9 10C9 9.5 9.5 9 10 9C10.5 9 18.5017 9 19.0009 9C19.5 9 20.0009 8.5 20.0009 8C20.0009 7.5 20.0009 1 20.0009 1C20.0009 0.447715 19.5531 0 19.0009 0H1.00085ZM2.00085 18V2H18.0009L18 7H8H7L7.00085 18H2.00085ZM12.0009 11C11.4486 11 11.0009 11.4477 11.0009 12V19C11.0009 19.5523 11.4486 20 12.0009 20H19.0009C19.5531 20 20.0009 19.5523 20.0009 19V12C20.0009 11.4477 19.5531 11 19.0009 11H12.0009ZM13.0009 18V13H18.0009V18H13.0009Z"
        fill="white"
      />
    </svg>
  );
}
