import { Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Menu from '../MenuBar/Menu/Menu';
import MenuScreen from '../MenuBar copy/Menu/MenuScreen';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: '#292929',
    color: 'white',
    paddingLeft: '1em',
    display: 'none',
    height: `${theme.mobileTopBarHeight}px`,
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      position: 'fixed',
      width: '100%',
      top: 0,
      left: 0,
      zIndex: 10,
      padding: '0 22px 0 24px',
    },
  },
  roomControl: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  roomName: {
    paddingLeft: '0',
  },
  endCallButton: {
    height: '28px',
    fontSize: '0.85rem',
    padding: '0 0.6em',
  },
  settingsButton: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'transparent',
      border: 'none',
      height: '28px',
      minWidth: '28px',
      padding: 0,
      margin: '0 12px 0 0',
      transition: '0.3s',
    },
  },
}));

export default function MobileTopMenuBar() {
  const classes = useStyles();
  const { room } = useVideoContext();

  return (
    <Grid container alignItems="center" justifyContent="space-between" className={classes.container}>
      <Typography className={classes.roomName} variant="subtitle1">
        {room!.name}
      </Typography>
      <div className={classes.roomControl}>
        <Menu buttonClassName={classes.settingsButton} />
        <MenuScreen />
      </div>
    </Grid>
  );
}
