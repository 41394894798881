import React, { useState } from 'react';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../constants';
import { FormControl, MenuItem, Typography, Select, Theme } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';
import VideoTrack from '../../VideoTrack/VideoTrack';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '2em auto',
    '& video': {
      maxHeight: '200px',
      borderRadius: '8px',
    },
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '100%',
  },
  dropdown: {
    border: '1px solid #292929',
    borderRadius: '8px',
    '& ul': {
      borderRadius: '8px',
      padding: '16px',
    },
    '&:hover': {
      backgroundColor: '#C5D7FB52',
      boxShadow: 'none',
    },
    [theme.breakpoints.down('xs')]: {
      color: 'white',
    },
  },
  item: {
    margin: '0 16px',
    padding: '24px',
    borderRadius: '8px',
    '&:first-child': {
      margin: '16px 16px 0 16px',
    },
    '&:last-child': {
      margin: '0 16px 16px 16px',
    },
    '&:hover': {
      backgroundColor: '#C5D7FB52',
      boxShadow: 'none',
    },
  },
}));

export default function VideoInputList() {
  const classes = useStyles();
  const { videoInputDevices } = useDevices();
  const { localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;

  function replaceTrack(newDeviceId: string) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  console.log('LOCALVIDEOTRACK', localVideoTrack);

  return (
    <div>
      {localVideoTrack && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      )}
      {videoInputDevices.length > 1 ? (
        <FormControl fullWidth>
          <p className={classes.subtitle}>Câmera</p>
          <Select
            className={classes.dropdown}
            onChange={e => replaceTrack(e.target.value as string)}
            value={localVideoInputDeviceId || ''}
            variant="outlined"
          >
            {videoInputDevices.map(device => (
              <MenuItem className={classes.item} value={device.deviceId} key={device.deviceId}>
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        <>
          <p className={classes.subtitle}>Câmera</p>
          <Typography>{localVideoTrack?.mediaStreamTrack.label || 'Não há video'}</Typography>
        </>
      )}
    </div>
  );
}
