import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';
import { Client } from 'twilio-chat';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

type ChatContextType = {
  isChatWindowOpen: boolean;
  setIsChatWindowOpen: (isChatWindowOpen: boolean) => void;
  connect: (token: string) => void;
  hasUnreadMessages: boolean;
  messages: [];
  conversation: any | null;
  counter: number;
};

export const ChatContext = createContext<ChatContextType>(null!);

export const ChatProvider: React.FC = ({ children }) => {
  const { room, onError } = useVideoContext();
  const isChatWindowOpenRef = useRef(false);
  const [isChatWindowOpen, setIsChatWindowOpen] = useState(false);
  const [conversation, setConversation] = useState<any | null>(null);
  const [messages, setMessages] = useState<any>([]);
  const [counter, setCounter] = useState(0);
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false);
  const [chatClient, setChatClient] = useState<Client>();

  const connect = useCallback(
    (token: string) => {
      const client = new Client(token);

      const handleClientInitialized = (state: string) => {
        if (state === 'initialized') {
          // @ts-ignore
          window.chatClient = client;
          setChatClient(client);
        } else if (state === 'failed') {
          onError(new Error("There was a problem connecting to Twilio's conversation service."));
        }
      };

      client.on('stateChanged', handleClientInitialized);

      return () => {
        client.off('stateChanged', handleClientInitialized);
      };
    },
    [onError]
  );

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message: any) => {
        setMessages((oldMessages: any) => [...oldMessages, message]);
      };
      conversation.getMessages().then((newMessages: any) => {
        setMessages(newMessages.items);
      });
      conversation.on('messageAdded', handleMessageAdded);
      return () => {
        conversation.off('messageAdded', handleMessageAdded);
      };
    }
  }, [conversation]);

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      if (counter < 10) {
        setCounter(counter + 1);
      }
      setHasUnreadMessages(true);
    }
  }, [messages]);

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen;
    if (isChatWindowOpen) {
      setCounter(0);
      setHasUnreadMessages(false);
    }
  }, [isChatWindowOpen]);

  useEffect(() => {
    if (room && chatClient) {
      chatClient
        .getChannelByUniqueName(room.name) // nunca vaia achar
        .then(newConversation => {
          if (newConversation.status !== 'joined') {
            newConversation.join();
          }
          //@ts-ignore
          window.chatConversation = newConversation;
          setConversation(newConversation);
        })
        .catch(e => {
          // catch async/ await
          chatClient
            .createChannel({
              uniqueName: room.name,
              friendlyName: room.name,
            })
            .then(channel => {
              console.log(channel);
              if (channel.status !== 'joined') {
                channel.join();
              }
              //@ts-ignore
              // window.chatConversation = newConversation;
              setConversation(channel);
            });
          // onError(new Error('There was a problem getting the Conversation associated with this room.'));
        });
    }
  }, [room, chatClient, onError]);

  return (
    <ChatContext.Provider
      value={{ isChatWindowOpen, setIsChatWindowOpen, connect, hasUnreadMessages, messages, conversation, counter }}
    >
      {children}
    </ChatContext.Provider>
  );
};
