import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import buttonColors from '../../../theme/buttonColors';
import iconColors from '../../../theme/iconColors';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

import EndCallIcon from '../../../icons/endCallIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      backgroundColor: buttonColors.cancel,
      margin: '0 10px 0 10px',
      borderRadius: '50%',
      padding: '0',
      minWidth: '0',
      width: '3rem',
      height: '3rem',
      zIndex: 99,

      '&:hover': {
        backgroundColor: buttonColors.onHoverEndCall,
        boxShadow: 'none',
        '& svg path': {
          fill: iconColors.onHoverEndCall,
        },
      },
      '&:focus': {
        outline: 'rgb(255, 138, 0) solid 2px',
        outlineOffset: '2px',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { disconnect } = useVideoContext();
  const message = 'disconnect';

  const handleEndCall = () => {
    window.parent.postMessage(message, '*'); //disconnect message to iframes and others enviroments
    disconnect();
  };

  return (
    <Button
      onClick={handleEndCall}
      className={clsx(classes.button, props.className)}
      onMouseDown={e => e.preventDefault()}
    >
      <EndCallIcon />
    </Button>
  );
}
