import { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import ChatIcon from '../../../icons/ChatIcon';
import ChatIconOpen from '../../../icons/ChatIconOpen';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { createStyles, Theme } from '@material-ui/core/styles';
import buttonColors from '../../../theme/buttonColors';
import iconColors from '../../../theme/iconColors';

import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export const ANIMATION_DURATION = 700;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonChat: {
      backgroundColor: buttonColors.primary,
      margin: '0 10px 0 10px',
      borderRadius: '50%',
      padding: '0',
      marginRight: '88px',
      minWidth: '0',
      width: '48px',
      height: '48px',
      [theme.breakpoints.down('sm')]: {
        margin: '0 10px 0 10px',
        minHeight: '48px',
      },

      '&:hover': {
        backgroundColor: buttonColors.onHover,
        boxShadow: 'none',
        '& svg path': {
          fill: iconColors.onHover,
        },
      },
      '&:focus': {
        outline: 'rgb(255, 138, 0) solid 2px',
        outlineOffset: '2px',
      },
    },
    buttonChatOpen: {
      backgroundColor: buttonColors.disable,
      margin: '0 10px 0 10px',
      borderRadius: '50%',
      padding: '0',
      marginRight: '88px',
      minWidth: '0',
      width: '3rem',
      height: '3rem',
      [theme.breakpoints.down('sm')]: {
        margin: '0 10px 0 10px',
      },

      '&:hover': {
        backgroundColor: buttonColors.onHover,
        boxShadow: 'none',
        '& svg path': {
          fill: iconColors.onHover,
        },
      },
      '&:focus': {
        outline: 'rgb(255, 138, 0) solid 2px',
        outlineOffset: '2px',
      },
    },
    iconContainer: {
      position: 'relative',
      display: 'flex',
    },
    circle: {
      width: '16px',
      height: '16px',
      backgroundColor: '#F05656',
      borderRadius: '50%',
      position: 'absolute',
      top: '-20px',
      left: '20px',
      opacity: 1,
      transition: `opacity ${ANIMATION_DURATION * 0.5}ms ease-in`,
    },
    circle9plus: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: '1px 3px 1px 4px',
      gap: '8px',
      position: 'absolute',
      width: '26px',
      height: '16px',
      left: '20px',
      top: '-20px',
      background: '#F05656',
      border: '1px solid #F05656',
      borderRadius: '8px',
    },
    countMessages: {
      position: 'absolute',
      top: '-18px',
      left: '4px',
      color: '#FFFFFF',
      fontSize: '14px',
    },
    hasUnreadMessages: {
      opacity: 1,
    },
    ring: {
      border: '3px solid #5BB75B',
      borderRadius: '30px',
      height: '14px',
      width: '14px',
      position: 'absolute',
      left: '11px',
      top: '-5px',
      opacity: 0,
    },
    animateRing: {
      animation: `$expand ${ANIMATION_DURATION}ms ease-out`,
      animationIterationCount: 1,
    },
    '@keyframes expand': {
      '0%': {
        transform: 'scale(0.1, 0.1)',
        opacity: 0,
      },
      '50%': {
        opacity: 1,
      },
      '100%': {
        transform: 'scale(1.4, 1.4)',
        opacity: 0,
      },
    },
  })
);

export default function ToggleChatButton() {
  const classes = useStyles();
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { isChatWindowOpen, setIsChatWindowOpen, conversation, hasUnreadMessages, counter } = useChatContext();
  const [counterMessages, setCounterMessages] = useState(0);
  const { setIsBackgroundSelectionOpen } = useVideoContext();

  const toggleChatWindow = () => {
    setIsChatWindowOpen(true);
    setIsBackgroundSelectionOpen(false);
  };

  useEffect(() => {
    if (shouldAnimate) {
      setTimeout(() => setShouldAnimate(false), ANIMATION_DURATION);
    }
  }, [shouldAnimate]);

  useEffect(() => {
    if (conversation && !isChatWindowOpen) {
      const handleNewMessage = () => setShouldAnimate(true);

      conversation.on('messageAdded', handleNewMessage);
      return () => {
        conversation.off('messageAdded', handleNewMessage);
      };
    }
  }, [conversation, isChatWindowOpen]);

  useEffect(() => {
    if (isChatWindowOpen) {
      setCounterMessages(0);
    }
  }, [isChatWindowOpen]);
  useEffect(() => {
    setCounterMessages(counterMessages + 1);
  }, [conversation?.on]);

  return (
    <>
      {!isChatWindowOpen ? (
        <Button
          className={classes.buttonChat}
          onMouseDown={e => e.preventDefault()}
          data-cy-chat-button
          onClick={toggleChatWindow}
          disabled={false}
          startIcon={
            <div className={classes.iconContainer}>
              <div className={clsx(classes.ring, { [classes.animateRing]: shouldAnimate })} />
              {hasUnreadMessages && counter <= 9 ? (
                <div className={clsx(classes.circle, { [classes.hasUnreadMessages]: hasUnreadMessages })}>
                  <p className={classes.countMessages}>{counter}</p>
                </div>
              ) : hasUnreadMessages && counter > 9 ? (
                <div className={clsx(classes.circle9plus, { [classes.hasUnreadMessages]: hasUnreadMessages })}>
                  <p className={classes.countMessages}>9+</p>
                </div>
              ) : (
                <></>
              )}
            </div>
          }
        >
          <ChatIcon />
        </Button>
      ) : (
        <Button
          className={classes.buttonChatOpen}
          data-cy-chat-button
          onMouseDown={e => e.preventDefault()}
          onClick={toggleChatWindow}
          disabled={false}
          style={{ backgroundColor: buttonColors.disable }}
          startIcon={
            <div className={classes.iconContainer}>
              <div className={clsx(classes.ring, { [classes.animateRing]: shouldAnimate })} />
              {/* <div className={clsx(classes.circle, { [classes.hasUnreadMessages]: hasUnreadMessages })}></div> */}
            </div>
          }
        >
          <ChatIconOpen />
        </Button>
      )}
    </>
  );
}
