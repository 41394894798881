import React, { useState, useRef, useEffect } from 'react';
import AboutDialog from '../../AboutDialog/AboutDialog';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import MoreIcon from '@material-ui/icons/MoreVert';
import {
  Button,
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  Divider,
} from '@material-ui/core';

import { useAppState } from '../../../state';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '../../../icons/CheckIcon';
import VisualizationIcon from '../../../icons/visualizationIcon';
import FullScreenIcon from '../../../icons/FullScreenIcon';
import GaleryViewIcon from '../../../icons/GaleryViewIcon';
import BackIcon from '../../../icons/BackIcon';
import ExitFullScreenIcon from '../../../icons/ExitFullScreenIcon';

export const IconContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

const useStyles = makeStyles((theme: Theme) => ({
  ScreenTypeButton: {
    background: 'black',
    position: 'relative',
    top: '12px',
    right: '88px',
    gap: '8px',
    padding: '0px 24px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '500px',
    width: '155px',
    height: '48px',
    opacity: 0.72,
    zIndex: 15,

    [theme.breakpoints.down('sm')]: {
      width: '30px',
      height: '30px',
      top: 'auto',
      right: 'auto',
      padding: '0px',
      opacity: 1,
      background: 'transparent',
    },
  },
  Icon: {
    marginLeft: '20px',
  },
  IconContainer: {
    marginRight: '18px',
    [theme.breakpoints.down('sm')]: {
      marginRight: '16px',
    },
  },
  Visualizar: {
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '20px',
      lineHeight: '133%',
      letterSpacing: '-0.01em',
    },
  },
  Telacheia: {
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '150%',
    },
  },
  Galeria: {
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '150%',
    },
  },
  Palestrante: {
    [theme.breakpoints.down('sm')]: {
      color: 'white',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '150%',
    },
  },
  CheckIcon: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  ContainerSelection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subMenu: {
    opacity: 0.72,
    marginTop: '80px',
    borderRadius: '8px',
    boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.16)',
    '& ul': {
      background: 'black',
      '& li': {
        margin: '20px',
        padding: '10px',
      },
    },
    [theme.breakpoints.down('sm')]: {
      borderRadius: 'none',
      boxShadow: 'none',
      '& ul': {
        background: '#292929',
        width: '100%',
        height: '100%',
        '& li': {
          margin: '20px 24px 20px 24px !important',
          padding: '0px !important',
        },
      },
      '& .MuiPaper-root': {
        maxWidth: 'none',
        maxHeight: 'none',
        width: '100vw',
        height: '100vh',
        left: '0px',
      },
      marginTop: '0px',
      maxHeight: '100vh',
      width: '100%',
      height: '100%',
      left: '0px !important',
      top: '0px',
      opacity: 1,
    },
  },
}));

export default function MenuScreen() {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  const { setIsGalleryViewActive, isGalleryViewActive } = useAppState();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const classes = useStyles();

  function toggleFullscreen() {
    const element = document.querySelector<HTMLBodyElement>('body');

    if (!document.fullscreenElement) {
      setIsFullscreen(true);
      element?.requestFullscreen().catch(err => {
        alert(`Erro ao selecionar o modo tela cheia: ${err.message} (${err.name})`);
      });
    } else {
      setIsFullscreen(false);
      document.exitFullscreen();
    }
  }

  useEffect(() => {
    if (document.fullscreenElement) {
      setIsFullscreen(true);
    }
  }, [isGalleryViewActive]);

  return (
    <>
      <Button
        onClick={() => setMenuOpen(isOpen => !isOpen)}
        ref={anchorRef}
        className={classes.ScreenTypeButton}
        data-cy-more-button
        style={{ color: '#ffffff', display: 'flex' }}
      >
        {isMobile ? (
          isGalleryViewActive ? (
            <GaleryViewIcon />
          ) : (
            <VisualizationIcon />
          )
        ) : (
          <>
            <VisualizationIcon /> <p className={classes.Icon}>Visualizar</p>
          </>
        )}
      </Button>
      <MenuContainer
        marginThreshold={isMobile ? 0 : 32}
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: isMobile ? 'top' : 'top',
          horizontal: isMobile ? 'left' : 'right',
        }}
        transitionDuration={600}
        className={classes.subMenu}
      >
        <MenuItem
          onClick={() => {
            setMenuOpen(false);
          }}
          style={{
            /* background: 'black', */
            color: 'white',
          }}
        >
          <IconContainer className={classes.IconContainer}>{<BackIcon />}</IconContainer>
          <Typography className={classes.Visualizar} variant="body1">
            {'Visualizar'}
          </Typography>
        </MenuItem>
        <Divider light style={{ backgroundColor: '#A3A3A3', margin: '0' }}></Divider>
        <MenuItem
          onClick={() => {
            toggleFullscreen();
            setMenuOpen(false);
          }}
          style={{
            /* background: 'black', */
            color: 'white',
          }}
        >
          <IconContainer className={classes.IconContainer}>
            {!isFullscreen ? <FullScreenIcon /> : <ExitFullScreenIcon />}
          </IconContainer>
          <Typography className={classes.Telacheia} variant="body1">
            {!isFullscreen ? 'Tela Cheia' : 'Sair de tela cheia'}
          </Typography>
        </MenuItem>
        <Divider light style={{ backgroundColor: '#A3A3A3', margin: '0 10px 0 10px' }}></Divider>
        <MenuItem
          onClick={() => {
            setIsGalleryViewActive(isGallery => !isGallery);
            setMenuOpen(false);
          }}
        >
          <div className={classes.ContainerSelection}>
            <IconContainer className={classes.IconContainer}>
              <GaleryViewIcon />
            </IconContainer>
            <Typography
              style={{
                ...(isGalleryViewActive && {
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '133%',
                  letterSpacing: '-0.01em',
                }),
              }}
              className={classes.Galeria}
              variant="body1"
            >
              Exibição galeria
            </Typography>
          </div>
          <div className={classes.CheckIcon}> {isGalleryViewActive && <CheckIcon />}</div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setIsGalleryViewActive(isGallery => !isGallery);
            setMenuOpen(false);
          }}
        >
          <div className={classes.ContainerSelection}>
            <IconContainer className={classes.IconContainer}>
              <VisualizationIcon />
            </IconContainer>
            <Typography
              style={{
                ...(!isGalleryViewActive && {
                  fontWeight: 700,
                  fontSize: '16px',
                  lineHeight: '133%',
                  letterSpacing: '-0.01em',
                }),
              }}
              className={classes.Palestrante}
              variant="body1"
            >
              Exibição palestrante
            </Typography>
          </div>
          <div className={classes.CheckIcon}>{!isGalleryViewActive && <CheckIcon />}</div>
        </MenuItem>

        {/* <MenuItem onClick={() => setAboutOpen(true)}>
          <IconContainer>
            <InfoIconOutlined />
          </IconContainer>
          <Typography variant="body1">About</Typography>
        </MenuItem> */}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
