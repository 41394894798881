import React from 'react';

export default function EndCallIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.70723 0.292893C1.3167 -0.0976311 0.68354 -0.0976311 0.293015 0.292893C-0.097509 0.683417 -0.097509 1.31658 0.293015 1.70711L6.58591 8L0.293015 14.2929C-0.097509 14.6834 -0.097509 15.3166 0.293015 15.7071C0.68354 16.0976 1.3167 16.0976 1.70723 15.7071L8.00012 9.41421L14.293 15.7071C14.6835 16.0976 15.3167 16.0976 15.7072 15.7071C16.0978 15.3166 16.0978 14.6834 15.7072 14.2929L9.41434 8L15.7072 1.70711C16.0978 1.31658 16.0978 0.683417 15.7072 0.292893C15.3167 -0.0976311 14.6835 -0.0976311 14.293 0.292893L8.00012 6.58579L1.70723 0.292893Z"
        fill="white"
      />
    </svg>
  );
}
