import React from 'react';

export default function VideoOffIcon() {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.49 0.523682C13.4036 0.439137 13.2957 0.379596 13.178 0.351416C13.0603 0.323236 12.9371 0.327476 12.8216 0.363682L0.789433 4.36368C0.660616 4.40672 0.548006 4.48789 0.466606 4.59637C0.385205 4.70484 0.338878 4.83548 0.333796 4.97088C0.328714 5.10628 0.365119 5.24 0.43816 5.35425C0.511201 5.46849 0.617411 5.55782 0.742642 5.61035L6.13036 7.86368L8.38973 13.257C8.4409 13.3775 8.5265 13.4803 8.63585 13.5526C8.74521 13.625 8.87349 13.6636 9.0047 13.6637C9.14024 13.6586 9.27101 13.6125 9.37966 13.5315C9.48831 13.4506 9.56969 13.3385 9.61299 13.2103L13.6237 1.21035C13.6674 1.09478 13.678 0.969371 13.6544 0.848124C13.6308 0.726876 13.5739 0.614553 13.49 0.523682ZM8.93117 11.097L7.43384 7.51035L9.4793 5.47035C9.60517 5.34481 9.67589 5.17455 9.67589 4.99702C9.67589 4.81948 9.60517 4.64922 9.4793 4.52368C9.35343 4.39815 9.18271 4.32762 9.0047 4.32762C8.82669 4.32762 8.65597 4.39815 8.5301 4.52368L6.48464 6.56368L2.88837 5.07035L11.9593 2.05035L8.93117 11.097Z"
        fill="white"
      />
    </svg>
  );
}
