import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

//hooks
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
//components
import IntroContainer from '../IntroContainer/IntroContainer';
import OtpInput from 'react-otp-input';
import Modal from '../ModalFeedback/modal';
import Header from '../Header/Header';
import { PasswordNotification } from '../PasswordNotification/PasswordNotification';
//styles
import { makeStyles, Theme } from '@material-ui/core';
import backgroundColors from '../../theme/backgroundColors';
import buttonColors from '../../theme/buttonColors';
import LoadingIcon from '../../icons/Loading';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens(props: any) {
  const history = useHistory();
  const location = useLocation();

  const [password, setPassword] = useState('');
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalClosed, setModalClosed] = useState<boolean>(false);
  const [notifyMessageOpen, setNotifyMessageOpen] = useState(false);
  const [notifyMessageClosed, setNotifyMessageClosed] = useState(false);
  const [showKeyboard, setShowKeyboard] = useState(false);
  const [enable, setEnable] = useState<boolean>(false);

  const { isAcquiredLocalTracks } = useVideoContext();

  const params = location.pathname.split('/').filter(item => item);
  const roomType = params[1] === 'medico' ? 'Doctor' : 'Patient';
  const roomId = params[2];

  const urlSearchParams = new URLSearchParams(location.search);
  const searchParams = Object.fromEntries(urlSearchParams.entries());
  const channel = searchParams.channel;
  const token = searchParams.token;

  const { isFetching, getToken, postToken, setCredentials } = useAppState();
  const listener = () => {
    const MIN_KEYBOARD_HEIGHT = 300; // N.B.! this might not always be correct

    const isMobile = window.innerWidth < 768;
    setShowKeyboard(isMobile && window.screen.height - MIN_KEYBOARD_HEIGHT > window.visualViewport.height);
  };

  window.visualViewport.addEventListener('resize', listener);

  useEffect(() => {
    if (password.length === 4) {
      setEnable(true);
    } else {
      setEnable(false);
    }
  }, [password]);

  const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
    },
    containerConnecting: {
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      '& svg path': {
        fill: '#292929',
      },
    },
    loadMessage: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
      color: '#292929',
      marginTop: '-88px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    buttonEnter: {
      background: buttonColors.primary,
      color: backgroundColors.primaryBrand,
      marginTop: '124px',
      borderRadius: '500px',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 500,
      border: 'none',
      padding: '0px 32px',
      width: '111px',
      height: '56px',
      cursor: 'pointer',
      transition: '0.3s',
      '&:disabled': {
        backgroundColor: 'rgba(0, 0, 0, 0.08)',
        color: 'rgba(0, 0, 0, 0.32)',
      },
      ...(enable && {
        '&:hover': {
          backgroundColor: buttonColors.onHover,
          color: '#0A3A99',
          transition: 'none',
        },
      }),
      '&:focus': {
        outline: 'rgb(255, 138, 0) solid 2px',
        outlineOffset: '2px',
        transition: 'none',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '210px',
        width: '86%',
        height: '48px',
        position: 'absolute',
        left: '35px',
        bottom: '34px',
        padding: '0px 32px',
        ...(showKeyboard && {
          display: 'none',
        }),
      },
      ...(isFetching && {
        background: buttonColors.primary,
        color: backgroundColors.primaryBrand,
        marginTop: '100px',
        borderRadius: '500px',
        border: 'none',
        width: '56px',
        height: '56px',
        padding: '0px',
        cursor: 'initial',
        '& svg path': {
          fill: '#FFFFFF',
        },
        '&:hover': {
          background: buttonColors.primary,
          color: backgroundColors.primaryBrand,
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: '210px',
          position: 'absolute',
          left: '43%',
          bottom: '34px',
        },
      }),
    },
    buttonLoad: {
      background: buttonColors.primary,
      color: backgroundColors.primaryBrand,
      marginTop: '100px',
      borderRadius: '500px',
      border: 'none',
      width: '56px',
      height: '56px',
      '& svg path': {
        fill: '#FFFFFF',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '210px',
        position: 'absolute',
        left: '43%',
        bottom: '34px',
      },
    },
    load: {
      width: '20px',
      height: '20px',
      animation: '$spin 1s linear infinite',
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1.5em 0 3.5em',
      '& div:not(:last-child)': {
        marginRight: '1em',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '1.5em 0 2em',
      },
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  }));

  const classes = useStyles();

  const handleJoin = () => {
    if (!isFetching) {
      // setAuthError(false);

      setNotifyMessageOpen(false);
      setNotifyMessageClosed(false);
      setModalOpen(false);
      setModalClosed(false);

      postToken(roomId, password, roomType, token, channel).then(response => {
        if (response.statusCode !== 13) {
          console.log('response do post - handlejoin', response);
          if (response.statusCode === 148 || response.statusCode === 6 || response.errorType === 'ReferenceError') {
            history.replace(`/salaexpirada`);
            return;
          }
        }
        getToken(roomId, password, roomType, token, channel).then(Response => {
          if (Response.errorType) {
            console.log('response do get - handlejoin', Response);
            setModalOpen(true);
            return;
          }

          if (Response.statusCode === 401) {
            // set error
            // setAuthError(true);
            setNotifyMessageOpen(true);
            setNotifyMessageClosed(false);
          }
          const { tokenVideo } = Response.data;

          setCredentials({
            tokenVideo,
            tokenChat: tokenVideo,
          });
          history.replace(`/atendimento/${params[1]}/${roomId}${channel}${location.search}`);
        });
      });
    }
  };

  const handlePasswordChange = (otpPassword: number) => {
    setPassword(String(otpPassword));
    setNotifyMessageOpen(false);
    setNotifyMessageClosed(false);
  };

  const handleCloseNotifyMessage = () => {
    setNotifyMessageOpen(false);
    setNotifyMessageClosed(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setModalClosed(true);
  };
  useEffect(() => {
    if (Boolean(searchParams?.authError)) {
      // setAuthError(true);
    }

    if (Boolean(searchParams?.roomError)) {
      // setModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAcquiredLocalTracks) {
      handleJoin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAcquiredLocalTracks]);

  function handleKeyPress(e: any) {
    var key = e.key;
    if (key === 'Enter' && password.length === 4) {
      e.target.blur();
      handleJoin();
    }
  }

  return (
    <div className={classes.container} onKeyPress={e => handleKeyPress(e)}>
      <Header />
      {
        /* !isConnecting && */ <IntroContainer>
          <OtpInput
            isInputNum={true}
            value={password}
            onChange={handlePasswordChange}
            numInputs={4}
            focusStyle={{
              outline: '#FF8A00 solid 2px',
              outlineOffset: '1px',
            }}
            inputStyle={{
              width: '56px',
              height: '56px',
              borderRadius: '16px',
              border: '1px solid #292929',
              color: '#292929',
              fontWeight: 700,
              fontFamily: 'Inter',
              fontSize: '24px',
              background: 'transparent',
              zIndex: 50,
              ...(notifyMessageOpen && {
                border: '2px solid #EA1F1F',
                background: '#FDC4C4',
              }),
              ...(showKeyboard && {
                marginBottom: '30px',
              }),
            }}
            containerStyle={{
              display: 'flex',
              flexDirection: 'row',
              alignitems: 'flex-start',
              padding: '0px',
              gap: '8px',
            }}
          />
          <div className={classes.buttonContainer}>
            <button
              onMouseDown={e => e.preventDefault()}
              onClick={() => handleJoin()}
              disabled={!enable}
              className={classes.buttonEnter}
            >
              {!isFetching ? 'Entrar' : <LoadingIcon />}
            </button>
          </div>
        </IntroContainer>
      }
      <Modal type={'Error'} show={modalOpen} hide={modalClosed} closeModal={handleCloseModal} />
      <PasswordNotification show={notifyMessageOpen} hide={notifyMessageClosed} close={handleCloseNotifyMessage} />
    </div>
  );
}
