import React from 'react';

import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import {
  DialogContent,
  Typography,
  useMediaQuery,
  Divider,
  Dialog,
  Button,
  Theme,
  DialogTitle,
  Hidden,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VideoInputList from './VideoInputList/VideoInputList';
import CloseMessageIcon from '../../icons/CloseMessageIcon';
import buttonColors from '../../theme/buttonColors';

export default function DeviceSelectionDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const useStyles = makeStyles((theme: Theme) => ({
    '@global': {
      '*::-webkit-scrollbar': {
        width: '4px',
        marginRight: '8px',
      },
      '*::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#CCCCCC',
        borderRadius: '16px',
      },
    },
    container: {
      width: 'auto',
      minHeight: '400px',

      [theme.breakpoints.down('xs')]: {
        width: 'calc(100vw - 32px)',
      },
      '& .inputSelect': {
        width: '100%',
      },
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 24px',
      ...(isMobile && {
        borderBottom: '1px solid #A3A3A3',
      }),
    },
    title: {
      fontFamily: 'Inter',
      fontWeight: 700,
      fontSize: '24px',
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'transparent',
      width: '48px',
      height: '48px',
      borderRadius: '50%',
      border: 'none',
      cursor: 'pointer',
      transition: '0.3s',
      '& svg path': {
        fill: buttonColors.primary,
      },
      '&:hover': {
        backgroundColor: '#C5D7FBA3',
      },
    },
    paper: {
      boxShadow: '0px 16px 48px rgba(0, 0, 0, 0.24)',
      borderRadius: '8px',
      width: '40%',
      [theme.breakpoints.down('xs')]: {
        margin: '0px !important',
        width: '100%',
        height: '100%',
        position: 'absolute',
        bottom: 0,
        backgroundColor: '#292929',
        color: 'white !important',
        '& svg path': {
          fill: 'white',
        },
      },
    },
    headline: {
      marginBottom: '1.3em',
      fontSize: '1.1rem',
    },
    listSection: {
      margin: '2em 0 0.8em',
      '&:first-child': {
        margin: '1em 0 2em 0',
        ...(isMobile && {
          paddingBottom: '40px',
          borderBottom: '1px solid #A3A3A3',
        }),
      },
      '&:last-child': {
        marginBottom: '3rem',
      },
    },
  }));

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <div className={classes.titleContainer}>
        <p className={classes.title}>Configurações</p>
        <button className={classes.button} onClick={onClose}>
          <CloseMessageIcon />
        </button>
      </div>
      {!isMobile && <Divider />}
      <DialogContent className={classes.container}>
        <div className={classes.listSection}>
          <VideoInputList />
        </div>
        {!isMobile && <Divider />}
        <div className={classes.listSection}>
          <AudioInputList />
        </div>
        <div className={classes.listSection}>
          <AudioOutputList />
        </div>
      </DialogContent>
      {!isMobile && <Divider />}
    </Dialog>
  );
}
