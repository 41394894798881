import { useCallback, useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import { createStyles, Theme, makeStyles, Modal } from '@material-ui/core';
import buttonColors from '../../../theme/buttonColors';
import iconColors from '../../../theme/iconColors';

import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';

import useDevices from '../../../hooks/useDevices/useDevices';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import NoVideoIcon from '../../../icons/NoVideo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonVideoDesactivated: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px 16px',
      justifyContent: 'center',
      /*     position: 'absolute',
    left:"45.3%",
    top:"72%", */
      width: '256px',
      height: '70px',
      color: '#FFFFFF',
      bottom: '106px',
      zIndex: 99,
      background: '#000000',
      opacity: '0.72',
      borderRadius: '4px',
      [theme.breakpoints.down('sm')]: {
        left: '24.5%',
        top: '10%',
        minHeight: '48px',
      },
    },
    container: {
      width: '256px',
      height: '70px',
      margin: 'calc(100vh - 104px - 70px) calc((100vw + 175px)/2) 0 calc((100vw - 175px)/2) ',
      [theme.breakpoints.down('sm')]: {
        width: '256px',
        margin: '65px calc((100% - 256px)/2) 0 calc((100% - 256px)/2)',
        display: 'flex',
        alignSelf: 'center',
        justifySelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      },
    },

    NoVideoText: {
      fontWeight: 500,
      fontSize: '14px',
    },

    buttonVideo: {
      backgroundColor: buttonColors.primary,
      margin: '0 10px 0 10px',
      borderRadius: '50%',
      padding: '0',
      minWidth: '0',
      width: '3rem',
      height: '3rem',
      zIndex: 99,
      [theme.breakpoints.down('sm')]: {
        minHeight: '48px',
      },
      '& div': {},

      '&:hover': {
        backgroundColor: buttonColors.onHover,
        boxShadow: 'none',
        '& svg path': {
          fill: iconColors.onHover,
        },
      },
      '&:focus': {
        outline: 'rgb(255, 138, 0) solid 2px',
        outlineOffset: '2px',
      },
    },
    buttonVideoDisabled: {
      backgroundColor: buttonColors.disable,
      margin: '0 10px 0 10px',
      borderRadius: '50%',
      padding: '0',
      minWidth: '0',
      width: '3rem',
      height: '3rem',
      [theme.breakpoints.down('sm')]: {
        minHeight: '48px',
      },
      '&:hover': {
        backgroundColor: buttonColors.onHover,
        boxShadow: 'none',
        '& svg path': {
          fill: iconColors.onHover,
        },
      },
      '&:focus': {
        outline: 'rgb(255, 138, 0) solid 2px',
        outlineOffset: '2px',
      },
    },
  })
);

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const { hasVideoInputDevices } = useDevices();
  const classes = useStyles(props);

  const [modalVideoOpen, setModalVideoOpen] = useState<boolean>(false);
  //const [modalVideoClosed, setModalVideoClosed] = useState<boolean>(false);

  const [hasVideo, setHasVideo] = useState(false);
  navigator.mediaDevices
    .getUserMedia({ video: true })
    .then(function(stream) {
      if (stream.getVideoTracks().length > 0 && stream.getAudioTracks().length > 0) {
        setHasVideo(false);
      } else {
        setHasVideo(true);
      }
    })
    .catch(function(error) {
      // code for when there is an error
    });

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  const handleClick = () => {
    if (!hasVideo) {
      handleNoVideo();
    } else {
      toggleVideo();
    }
  };

  const handleNoVideo = () => {
    setModalVideoOpen(!modalVideoOpen);
    setTimeout(() => {
      setModalVideoOpen(false);
    }, 5000);
    //setModalVideoClosed(true);
  };

  // window.addEventListener('click', function(e){
  //   if (document.getElementsByClassName('buttonVideo')){
  //     setModalVideoOpen(false);
  //   } else{
  //     // Clicked outside the box
  //   }
  // });

  // setTimeout(()=> {
  //   setModalVideoOpen(false);
  // },5000)

  return (
    <>
      <Modal
        open={modalVideoOpen}
        onClose={() => setModalVideoOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0)' } }}
      >
        <div className={classes.container}>
          <div className={classes.buttonVideoDesactivated}>
            <p>Parece que houve um problema ao tentar acessar sua câmera</p>
          </div>
        </div>
      </Modal>

      {isVideoEnabled ? (
        <Button
          className={classes.buttonVideo}
          onClick={handleClick}
          onMouseDown={e => e.preventDefault()}
          disabled={!hasVideoInputDevices || props.disabled}
        >
          {!hasVideo ? <NoVideoIcon /> : isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
        </Button>
      ) : (
        <Button className={classes.buttonVideoDisabled} onClick={handleClick} onMouseDown={e => e.preventDefault()}>
          {!hasVideo ? <NoVideoIcon /> : isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
        </Button>
      )}
    </>
  );
}
