import React from 'react';

export default function AvatarIcon() {
  return (
    <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.06446 1.93571C6.37218 0.243436 3.62847 0.243436 1.9362 1.93571C0.243924 3.62798 0.243924 6.3717 1.9362 8.06397L79.9362 86.064C81.6285 87.7562 84.3722 87.7562 86.0645 86.064C87.7567 84.3717 87.7567 81.628 86.0645 79.9357L71.9201 65.7913L81.3507 69.6736C82.6875 70.2239 84.2106 70.0714 85.4117 69.2669C86.6128 68.4625 87.3337 67.1121 87.3337 65.6665V22.3332C87.3337 20.8876 86.6128 19.5372 85.4117 18.7328C84.2106 17.9283 82.6875 17.7758 81.3507 18.3261L61.3333 26.5666C61.3071 23.1549 59.9405 19.8885 57.526 17.4741C55.0881 15.0361 51.7815 13.6665 48.3337 13.6665H19.7953L8.06446 1.93571ZM61.3337 52.0609V35.9388L78.667 28.8032V59.1965L61.3337 52.0609ZM52.667 33.0365V46.5382L28.4619 22.3332H48.3337C49.4829 22.3332 50.5851 22.7897 51.3978 23.6024C52.2104 24.415 52.667 25.5172 52.667 26.6665V33.0365ZM9.33366 26.6665C9.33366 24.2733 7.39356 22.3332 5.00033 22.3332C2.60709 22.3332 0.666992 24.2733 0.666992 26.6665V61.3332C0.666992 64.781 2.03663 68.0876 4.4746 70.5256C6.91258 72.9635 10.2192 74.3332 13.667 74.3332H48.3337C50.7269 74.3332 52.667 72.3931 52.667 69.9998C52.667 67.6066 50.7269 65.6665 48.3337 65.6665H13.667C12.5177 65.6665 11.4155 65.21 10.6029 64.3973C9.79021 63.5846 9.33366 62.4824 9.33366 61.3332V26.6665Z"
        fill="#CCCCCC"
      />
    </svg>
  );
}
