import React from 'react';
import clsx from 'clsx';
import { Link } from '@material-ui/core';
import linkify from 'linkify-it';
import { makeStyles } from '@material-ui/core/styles';
// import buttonColors from '../../../../theme/buttonColors';

// import Avatar from 'react-avatar';

const useStyles = makeStyles({
  messageContainer: {
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '8px 16px',
    wordBreak: 'break-word',
    backgroundColor: '#F5F5F5',
    color: '#292929',
    hyphens: 'auto',
    whiteSpace: 'pre-wrap',
  },
  isLocalParticipant: {
    backgroundColor: '#4985FD',
    color: '#ffffff',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  messageItem: {
    display: 'flex',
    justifyContent: 'flex-start',
    margin: '2px 0 0 ',
  },
  messageItemLocal: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  timeInfoLocal: {
    fontWeight: 400,
    fontSize: '12px',
    // color: '#666666',
  },
  timeInfo: {
    fontWeight: 400,
    fontSize: '12px',
    color: '#666666',
  },
});

interface TextMessageProps {
  body: string;
  isLocalParticipant: boolean;
  time: Date;
  dateCreated: string;
}

function addLinks(text: string, time: Date) {
  const matches = linkify().match(text);
  if (!matches) return text;

  const results = [];
  let lastIndex = 0;

  matches.forEach((match, i) => {
    results.push(text.slice(lastIndex, match.index));
    results.push(
      <Link target="_blank" rel="noreferrer" href={match.url} key={i}>
        {match.text}
      </Link>
    );
    lastIndex = match.lastIndex;
  });

  results.push(text.slice(lastIndex, text.length));

  return results;
}

export default function TextMessage({ body, isLocalParticipant, time, dateCreated }: TextMessageProps) {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.messageItem, {
        [classes.messageItemLocal]: isLocalParticipant,
      })}
    >
      {/* {isLocalParticipant && (
        <Avatar
          name="Dr Alberto"
          size="40"
          textSizeRatio={0.1}
          color="#F5F5F5"
          fgColor="#292929"
          round={true}
          style={{ marginRight: '16px', marginTop: '5px' }}
        />
      )} */}
      <div
        className={clsx(classes.messageContainer, {
          [classes.isLocalParticipant]: isLocalParticipant,
        })}
      >
        <div>{addLinks(body, time)}</div>

        <div className={isLocalParticipant ? clsx(classes.timeInfoLocal) : clsx(classes.timeInfo)}>{dateCreated}</div>
      </div>
    </div>
  );
}
