import React from 'react';

export default function YoutubeIcon() {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.147 9.4589L12.1473 9.45875C12.7168 9.117 13.1025 8.60487 13.1025 7.99874C13.1025 7.39379 12.7182 6.87979 12.1506 6.53324L12.1506 6.53322L12.147 6.53109L9.67723 5.05126C9.08848 4.69802 8.43268 4.58911 7.88922 4.89582C7.34525 5.20284 7.09998 5.82113 7.09998 6.51001V9.48001C7.09998 10.1689 7.34525 10.7872 7.88922 11.0942C8.43268 11.4009 9.08848 11.292 9.67723 10.9388L12.147 9.4589ZM5 0.5H15C16.3864 0.5 17.5028 0.959943 18.2714 1.72855C19.0401 2.49716 19.5 3.61362 19.5 5V11C19.5 12.3864 19.0401 13.5028 18.2714 14.2714C17.5028 15.0401 16.3864 15.5 15 15.5H5C3.61362 15.5 2.49716 15.0401 1.72855 14.2714C0.959943 13.5028 0.5 12.3864 0.5 11V5C0.5 3.61362 0.959943 2.49716 1.72855 1.72855C2.49716 0.959943 3.61362 0.5 5 0.5Z"
        fill="#2C6EF2"
        stroke="#2C6EF2"
      />
    </svg>
  );
}
