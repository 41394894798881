import { LocalVideoTrack } from 'twilio-video';
import { useCallback, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';

export default function useLocalVideoToggle() {
  const { room, localTracks, getLocalVideoTrack, removeLocalVideoTrack, onError } = useVideoContext();
  const localParticipant = room?.localParticipant;
  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;
  const [isPublishing, setIspublishing] = useState(false);
  const toggleVideoEnabled = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        getLocalVideoTrack()
          .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: 'low' }))
          .catch(onError)
          .finally(() => {
            setIspublishing(false);
          });
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);
  return [!!videoTrack, toggleVideoEnabled] as const;

  // const { localTracks, removeLocalVideoTrack } = useVideoContext();
  // const videoTrack = localTracks.find(
  //   track => !track.name.includes('screen') && track.kind === 'video'
  // ) as LocalVideoTrack;
  // const isEnabled = useIsTrackEnabled(videoTrack);

  // const toggleVideoEnabled = useCallback(() => {
  //   if (videoTrack) {
  //     videoTrack.isEnabled ? videoTrack.disable().stop() : videoTrack.enable().restart();
  //   } else {
  //     removeLocalVideoTrack()
  //   }
  // }, [videoTrack]);

  // return [videoTrack && isEnabled, toggleVideoEnabled] as const;
}
