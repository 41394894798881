import React from 'react';
import { makeStyles, Theme, Typography, createStyles } from '@material-ui/core';
import Swoosh from './swoosh';
import VideoLogo from './VideoLogo';
import TwilioLogo from './TwilioLogo';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';
import backgroundColors from '../../theme/backgroundColors';
import fontColors from '../../theme/fontColors';
import AuthIllustration from '../../icons/authIllustration.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    background: {
      display: 'flex',
      flexDirection: 'column',
      background: backgroundColors.primaryBrand,
      height: '100%',
      position: 'absolute',
      width: '100%',
      zIndex: 0,
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '40px 24px 0 24px',
        position: 'relative',
        justifyContent: 'flex-start',
      },
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    containerText: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    mainText: {
      fontSize: '32px',
      fontWeight: 700,
      color: fontColors.title,
      marginBottom: '16px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    },
    subText: {
      fontSize: '20px',
      fontWeight: 400,
      color: fontColors.subtitle,
      width: '406px',
      [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
        width: '100%',
      },
    },
    container2: {
      position: 'relative',
      flex: '1',
    },
    innerContainer: {
      display: 'flex',
      width: '888px',
      height: '379px',
      borderRadius: '8px',
      boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
      overflow: 'hidden',
      position: 'relative',
      margin: 'auto',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        height: 'auto',
        width: 'calc(100% - 40px)',
        margin: 'auto',
        maxWidth: '400px',
      },
    },
    swooshContainer: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundImage: Swoosh,
      backgroundSize: 'cover',
      width: '296px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '100px',
        backgroundPositionY: '140px',
      },
    },
    logoContainer: {
      position: 'absolute',
      width: '210px',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
        textAlign: 'initial',
        '& svg': {
          height: '64px',
        },
      },
    },
    twilioLogo: {
      position: 'absolute',
      top: 0,
      left: 0,
      margin: '20px',
    },
    content: {
      background: 'transparent',
      width: '100%',
      height: '100%',
      paddingLeft: '0px',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '48px',

      [theme.breakpoints.down('sm')]: {},
    },
    title: {
      color: 'white',
      margin: '1em 0 0',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
        fontSize: '1.1rem',
      },
    },
    img: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  })
);

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();

  return (
    <div className={classes.background}>
      <div className={classes.container}>
        <div
          className={classes.img}
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
          }}
        >
          <img
            className={classes.img}
            style={{
              width: '513px',
              height: '513px',
            }}
            src={AuthIllustration}
          />
        </div>
        <div className={classes.containerText}>
          <Typography className={classes.mainText}>Olá! Vamos começar?</Typography>
          <Typography className={classes.subText}>
            Para iniciar seu atendimento, insira sua senha de 4 dígitos
          </Typography>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
