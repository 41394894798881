// Libs
import React, { Fragment, useEffect, useState } from 'react';
import { Route, withRouter, useLocation, useHistory } from 'react-router-dom';
import { useAppState } from './state';

const Private = (props: any) => {
  const [validated, setValidated] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { credentials } = useAppState();
  const location = useLocation();
  const history = useHistory();

  const validate = () => {
    const params = location.pathname.split('/').filter(item => item);
    const roomId = params[2];
    const roomType = params[1];

    if (roomType === 'paciente') {
      setValidated(true);
      setIsAuthenticated(true);
    }

    if (roomType === 'medico') {
      const urlSearchParams = new URLSearchParams(location.search);
      const searchParams = Object.fromEntries(urlSearchParams.entries());

      if (searchParams.passcode || (credentials.tokenVideo && credentials.tokenChat)) {
        setValidated(true);
        setIsAuthenticated(true);
      } else {
        history.replace(`/login/${roomType}/${roomId}${location.search}`);
      }

      // const passcode = searchParams.passcode; // CHANGE PASSCODE BASED ON URL PRODUCTION

      // getToken(roomId, passcode, 'Doctor').then(({ data }) => {
      //   const { tokenVideo, tokenChat } = data;

      //   props.saveCredentials({
      //     tokenChat,
      //     tokenVideo,
      //   });

      //   setValidated(true);
      //   setIsAuthenticated(true);
      // });
    }

    // if (roomType === 'paciente' && !props.credentials?.tokenVideo && !props.credentials?.tokenChat) {
    //   history.replace(`/login/${roomType}/${roomId}${location.search}`);
    // }
  };

  useEffect(() => {
    validate();
  }, [props.location.key]);

  const renderRoute = () => {
    const { component: Component, ...rest } = props;

    return <Route {...rest} component={Component} />;
  };

  return <Fragment>{validated && isAuthenticated && renderRoute()}</Fragment>;
};

export default withRouter(Private);
