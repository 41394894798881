import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import useChatContext from '../../../hooks/useChatContext/useChatContext';
import EndCallIcon from '../../../icons/endCallIcon';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '56px',
      background: '#FFFFFF',
      borderBottom: '1px solid #E4E7E9',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0px 24px',
    },
    text: {
      fontWeight: 500,
      fontSize: '16px',
    },
    closeChatWindow: {
      cursor: 'pointer',
      display: 'flex',
      background: 'transparent',
      width: '48px',
      height: '48px',
      border: '0',
      padding: '0px',
      marginLeft: '16px',

      justifyContent: 'center',
      alignItems: 'center',
      '& svg path': {
        fill: '#292929',
        boxSize: 'border-box',
      },
      '&:hover svg path': {
        fill: '#0A3A99',
      },
      '&:hover': {
        borderRadius: '50%',
        background: 'rgba(197, 215, 251, 0.64)',
      },
    },
  })
);

export default function ChatWindowHeader() {
  const classes = useStyles();
  const { setIsChatWindowOpen } = useChatContext();

  const setIsChatWindow = (e: any) => {
    setIsChatWindowOpen(false);
    e.target.blur();
  };

  return (
    <div className={classes.container}>
      <div className={classes.text}>Chat</div>
      <button className={classes.closeChatWindow} onClick={setIsChatWindow}>
        <EndCallIcon />
      </button>
    </div>
  );
}
