import Button from '@material-ui/core/Button';
import { Theme, createStyles, makeStyles, Modal } from '@material-ui/core';
import buttonColors from '../../../theme/buttonColors';
import iconColors from '../../../theme/iconColors';

import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import NoMicIcon from '../../../icons/NoMicIcon';
import { useState } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonAudioDesactivated: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '0px 16px',
      justifyContent: 'center',
      /*   position: 'absolute',
    left:"42.5%",
    top:"72%", */
      width: '256px',
      height: '70px',
      color: '#FFFFFF',
      bottom: '106px',
      zIndex: 99,
      background: '#000000',
      opacity: '0.72',
      borderRadius: '4px',
    },
    container: {
      width: '256px',
      height: '70px',
      margin: 'calc(100vh - 104px - 70px) calc((100vw - 256px + 48px)/2) 0 calc((100vw - 256px - 48px)/2) ',
      [theme.breakpoints.down('sm')]: {
        width: '256px',
        margin: '65px calc((100% - 256px)/2) 0 calc((100% - 256px)/2)',
        display: 'flex',
        alignSelf: 'center',
        justifySelf: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '48px',
      },
    },

    NoAudioText: {
      fontWeight: 500,
      fontSize: '14px',
    },

    buttonAudio: {
      backgroundColor: buttonColors.primary,
      margin: '0 10px 0 10px',
      borderRadius: '50%',
      padding: '0',
      minWidth: '0',
      width: '3rem',
      height: '3rem',
      zIndex: 99,
      [theme.breakpoints.down('sm')]: {
        minHeight: '48px',
      },

      '&:hover': {
        backgroundColor: buttonColors.onHover,
        boxShadow: 'none',
        '& svg path': {
          fill: iconColors.onHover,
        },
      },
      '&:focus': {
        outline: 'rgb(255, 138, 0) solid 2px',
        outlineOffset: '2px',
      },
    },
    buttonAudioDisable: {
      backgroundColor: buttonColors.disable,
      margin: '0 10px 0 10px',
      borderRadius: '50%',
      padding: '0',
      minWidth: '0',
      width: '3rem',
      height: '3rem',
      [theme.breakpoints.down('sm')]: {
        minHeight: '48px',
      },

      '&:hover': {
        backgroundColor: '#C5D7FB',
        boxShadow: 'none',
        '& svg path': {
          fill: iconColors.onHover,
        },
      },
      '&:focus': {
        outline: 'rgb(255, 138, 0) solid 2px',
        outlineOffset: '2px',
      },
    },
  })
);

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
  const classes = useStyles(props);

  const [modalAudioOpen, setModalAudioOpen] = useState<boolean>(false);
  //const [modalAudioClosed, setModalAudioClosed] = useState<boolean>(false);

  const handleClick = () => {
    if (!hasAudioTrack) {
      handleNoAudio();
    } else {
      toggleAudioEnabled();
    }
  };
  const handleNoAudio = () => {
    setModalAudioOpen(!modalAudioOpen);
    setTimeout(() => {
      setModalAudioOpen(false);
    }, 5000);
    //setModalVideoClosed(true);
  };

  return (
    <>
      <Modal
        open={modalAudioOpen}
        onClose={() => setModalAudioOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backgroundColor: 'rgba(0,0,0,0)' } }}
      >
        <div className={classes.container}>
          <div className={classes.buttonAudioDesactivated}>
            <p>Parece que houve um problema ao tentar acessar seu microfone</p>
          </div>
        </div>
      </Modal>
      {isAudioEnabled ? (
        <Button
          className={classes.buttonAudio}
          onClick={handleClick}
          onMouseDown={e => e.preventDefault()}
          disabled={!hasAudioTrack || props.disabled}
        >
          {!hasAudioTrack ? <NoMicIcon /> : isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
        </Button>
      ) : (
        <Button className={classes.buttonAudioDisable} onClick={handleClick} onMouseDown={e => e.preventDefault()}>
          {!hasAudioTrack ? <NoMicIcon /> : isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
        </Button>
      )}
    </>
  );
}
