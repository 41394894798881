import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { Grid } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',

      zIndex: 10,
    },
    grid: {
      '& button': {
        marginTop: '24px',
      },
    },

    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    endCallButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'fixed',
      bottom: 38,
      zIndex: 11,
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    text: {
      color: '#ffffff',
    },
  })
);

export default function MenuMobile() {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';

  return (
    <>
      {/* {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">You are sharing your screen</Typography>
          <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
        </Grid>
      )} */}
      <footer className={classes.container}>
        <EndCallButton className={classes.endCallButton} />
        <Grid
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'fixed',
            height: '34%',
            bottom: 38,
            right: 0,
            padding: '0 20px 0 24px',
            zIndex: 10,
          }}
        >
          <Grid item>
            <Grid
              className={classes.grid}
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-end',
                height: '100%',
              }}
            >
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS === 'true' && <ToggleChatButton />}
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
