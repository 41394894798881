import React from 'react';

export default function NoMicIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#EBEBEB" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 16C23.4696 16 22.9609 16.2107 22.5858 16.5858C22.2107 16.9609 22 17.4696 22 18H23C23.5523 18 24 18.4477 24 19C24 19.5523 23.5523 20 23 20H22V21H23C23.5523 21 24 21.4477 24 22C24 22.5523 23.5523 23 23 23H22C22 23.5523 21.5523 24 21 24C20.4477 24 20 23.5523 20 23V22V19V18C20 16.9391 20.4214 15.9217 21.1716 15.1716C21.9217 14.4214 22.9391 14 24 14C25.0609 14 26.0783 14.4214 26.8284 15.1716C27.5786 15.9217 28 16.9391 28 18C28 18.5523 27.5523 19 27 19C26.4477 19 26 18.5523 26 18C26 17.4696 25.7893 16.9609 25.4142 16.5858C25.0391 16.2107 24.5304 16 24 16ZM18.0037 22C18.556 22.0021 19.0021 22.4514 19 23.0037C18.9968 23.8789 19.229 24.7389 19.6723 25.4935C19.952 25.9697 19.7927 26.5825 19.3165 26.8622C18.8403 27.142 18.2275 26.9827 17.9478 26.5065C17.3228 25.4426 16.9955 24.2302 17 22.9963C17.0021 22.444 17.4515 21.998 18.0037 22ZM30.9999 23C30.9999 22.4477 30.5522 22 29.9999 22C29.4476 22 28.9999 22.4477 28.9999 23C28.9999 24.3261 28.4731 25.5979 27.5354 26.5355C26.5978 27.4732 25.326 28 23.9999 28L23.9962 28C23.525 28.0018 23.0582 27.9353 22.6099 27.8043L23.5309 26.8834C23.6708 26.9578 23.8305 27 24 27C25.0609 27 26.0783 26.5786 26.8284 25.8284C27.5786 25.0783 28 24.0609 28 23V22.4142L33.7071 16.7071C34.0976 16.3166 34.0976 15.6834 33.7071 15.2929C33.3166 14.9024 32.6834 14.9024 32.2929 15.2929L15.2929 32.2929C14.9024 32.6834 14.9024 33.3166 15.2929 33.7071C15.6834 34.0976 16.3166 34.0976 16.7071 33.7071L21.0625 29.3517C21.6776 29.6395 22.3314 29.8344 23.0024 29.9305C23.0008 29.9535 23 29.9766 23 30V32H22C21.4477 32 21 32.4477 21 33C21 33.5523 21.4477 34 22 34H24H26C26.5523 34 27 33.5523 27 33C27 32.4477 26.5523 32 26 32H25V30C25 29.976 24.9992 29.9522 24.9975 29.9286C26.4852 29.7144 27.8743 29.0251 28.9497 27.9497C30.2624 26.637 30.9999 24.8565 30.9999 23Z"
        fill="black"
        fill-opacity="0.32"
      />
      <circle cx="36" cy="12" r="8.5" fill="#FF8A00" stroke="#FF8A00" />
      <path
        d="M37.316 6.81818L37.1867 14.0717H35.8096L35.6803 6.81818H37.316ZM36.5006 17.0945C36.2255 17.0945 35.9902 16.9983 35.7947 16.8061C35.5991 16.6106 35.503 16.3752 35.5063 16.1001C35.503 15.8284 35.5991 15.5964 35.7947 15.4041C35.9902 15.2086 36.2255 15.1108 36.5006 15.1108C36.7691 15.1108 37.0011 15.2086 37.1966 15.4041C37.3922 15.5964 37.4916 15.8284 37.4949 16.1001C37.4916 16.2824 37.4436 16.4498 37.3508 16.6023C37.2613 16.7514 37.142 16.8707 36.9928 16.9602C36.8437 17.0497 36.6796 17.0945 36.5006 17.0945Z"
        fill="white"
      />
    </svg>
  );
}
