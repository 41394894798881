import React from 'react';

export default function VideoOffIcon() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.70711 0.958909C1.31658 0.568385 0.683418 0.568385 0.292893 0.958909C-0.0976311 1.34943 -0.0976311 1.9826 0.292893 2.37312L18.2929 20.3731C18.6834 20.7636 19.3166 20.7636 19.7071 20.3731C20.0976 19.9826 20.0976 19.3494 19.7071 18.9589L16.443 15.6948L18.6193 16.5907C18.9278 16.7177 19.2793 16.6825 19.5565 16.4969C19.8336 16.3112 20 15.9996 20 15.666V5.66602C20 5.33242 19.8336 5.02079 19.5565 4.83516C19.2793 4.64952 18.9278 4.61432 18.6193 4.74131L13.9999 6.64297C13.9939 5.85564 13.6785 5.10187 13.1213 4.5447C12.5587 3.98209 11.7956 3.66602 11 3.66602H4.41421L1.70711 0.958909ZM14 12.5263V8.80577L18 7.15911V14.1729L14 12.5263ZM12 8.13602V11.2518L6.41421 5.66602H11C11.2652 5.66602 11.5196 5.77137 11.7071 5.95891C11.8946 6.14645 12 6.4008 12 6.66602V8.13602ZM2 6.66602C2 6.11373 1.55228 5.66602 1 5.66602C0.447715 5.66602 0 6.11373 0 6.66602V14.666C0 15.4617 0.316071 16.2247 0.87868 16.7873C1.44129 17.3499 2.20435 17.666 3 17.666H11C11.5523 17.666 12 17.2183 12 16.666C12 16.1137 11.5523 15.666 11 15.666H3C2.73478 15.666 2.48043 15.5607 2.29289 15.3731C2.10536 15.1856 2 14.9312 2 14.666V6.66602Z"
        fill="#0A3A99"
      />
    </svg>
  );
}
