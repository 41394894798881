import React from 'react';

export default function NoVideoIcon() {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="48" height="48" rx="24" fill="#EBEBEB" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.7071 14.2929C15.3166 13.9024 14.6834 13.9024 14.2929 14.2929C13.9024 14.6834 13.9024 15.3166 14.2929 15.7071L32.2929 33.7071C32.6834 34.0976 33.3166 34.0976 33.7071 33.7071C34.0976 33.3166 34.0976 32.6834 33.7071 32.2929L30.443 29.0288L32.6193 29.9247C32.9278 30.0517 33.2793 30.0165 33.5565 29.8309C33.8336 29.6452 34 29.3336 34 29V19C34 18.6664 33.8336 18.3548 33.5565 18.1691C33.2793 17.9835 32.9278 17.9483 32.6193 18.0753L27.9999 19.977C27.9939 19.1896 27.6785 18.4359 27.1213 17.8787C26.5587 17.3161 25.7956 17 25 17H18.4142L15.7071 14.2929ZM28 25.8603V22.1398L32 20.4931V27.5069L28 25.8603ZM26 21.47V24.5858L20.4142 19H25C25.2652 19 25.5196 19.1054 25.7071 19.2929C25.8946 19.4804 26 19.7348 26 20V21.47ZM16 20C16 19.4477 15.5523 19 15 19C14.4477 19 14 19.4477 14 20V28C14 28.7956 14.3161 29.5587 14.8787 30.1213C15.4413 30.6839 16.2044 31 17 31H25C25.5523 31 26 30.5523 26 30C26 29.4477 25.5523 29 25 29H17C16.7348 29 16.4804 28.8946 16.2929 28.7071C16.1054 28.5196 16 28.2652 16 28V20Z"
        fill="black"
        fill-opacity="0.32"
      />
      <circle cx="36" cy="12" r="8.5" fill="#FF8A00" stroke="#FF8A00" />
      <path
        d="M37.316 6.81818L37.1867 14.0717H35.8096L35.6803 6.81818H37.316ZM36.5006 17.0945C36.2255 17.0945 35.9902 16.9983 35.7947 16.8061C35.5991 16.6106 35.503 16.3752 35.5063 16.1001C35.503 15.8284 35.5991 15.5964 35.7947 15.4041C35.9902 15.2086 36.2255 15.1108 36.5006 15.1108C36.7691 15.1108 37.0011 15.2086 37.1966 15.4041C37.3922 15.5964 37.4916 15.8284 37.4949 16.1001C37.4916 16.2824 37.4436 16.4498 37.3508 16.6023C37.2613 16.7514 37.142 16.8707 36.9928 16.9602C36.8437 17.0497 36.6796 17.0945 36.5006 17.0945Z"
        fill="white"
      />
    </svg>
  );
}
