import React from 'react';

export default function ErrorMessageIcon() {
  return (
    <svg width="20" height="33" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 2.00004C12.1217 2.00004 14.1566 2.8429 15.6569 4.34319C17.1571 5.84348 18 7.87831 18 10C18 12.1218 17.1571 14.1566 15.6569 15.6569C14.1566 17.1572 12.1217 18 9.99999 18C7.87826 18 5.84344 17.1572 4.34315 15.6569C2.84285 14.1566 2 12.1218 2 10.0001C2 7.87832 2.84285 5.84348 4.34315 4.34319C5.84344 2.8429 7.87827 2.00004 10 2.00004ZM17.0711 2.92898C15.1957 1.05362 12.6522 4.13284e-05 10 4.13284e-05C7.34784 4.16655e-05 4.8043 1.05361 2.92893 2.92898C1.05357 4.80434 -3.8724e-06 7.34789 -4.71534e-06 10.0001C-4.20957e-06 12.6522 1.05357 15.1958 2.92893 17.0711C4.80429 18.9465 7.34783 20 9.99999 20.0001C12.6522 20.0001 15.1957 18.9465 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 7.34787 18.9464 4.80434 17.0711 2.92898ZM14.2426 5.7574C14.6332 6.14793 14.6332 6.78109 14.2426 7.17162L11.4142 10L14.2426 12.8285C14.6332 13.219 14.6332 13.8522 14.2426 14.2427C13.8521 14.6332 13.219 14.6332 12.8284 14.2427L10 11.4143L7.17157 14.2427C6.78105 14.6332 6.14788 14.6332 5.75736 14.2427C5.36683 13.8522 5.36683 13.219 5.75736 12.8285L8.58579 10L5.75736 7.17162C5.36684 6.78109 5.36683 6.14793 5.75736 5.7574C6.14788 5.36688 6.78105 5.36688 7.17157 5.7574L10 8.58583L12.8284 5.7574C13.219 5.36688 13.8521 5.36688 14.2426 5.7574Z"
        fill="#710A0A"
      />
    </svg>
  );
}
