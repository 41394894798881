import React from 'react';

export default function VideoOnIcon() {
  return (
    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 3.66602C2 3.11373 2.44772 2.66602 3 2.66602H11C11.5523 2.66602 12 3.11373 12 3.66602V5.13602V10.196V11.666C12 12.2183 11.5523 12.666 11 12.666H3C2.44772 12.666 2 12.2183 2 11.666V3.66602ZM13.9999 11.6891C13.9875 13.3353 12.6492 14.666 11 14.666H3C1.34315 14.666 0 13.3229 0 11.666V3.66602C0 2.00916 1.34315 0.666016 3 0.666016H11C12.6492 0.666016 13.9875 1.99672 13.9999 3.64297L18.6193 1.74131C18.9278 1.61432 19.2793 1.64952 19.5565 1.83516C19.8336 2.02079 20 2.33242 20 2.66602V12.666C20 12.9996 19.8336 13.3112 19.5565 13.4969C19.2793 13.6825 18.9278 13.7177 18.6193 13.5907L13.9999 11.6891ZM14 9.52627L18 11.1729V4.15911L14 5.80577V9.52627Z"
        fill="white"
      />
    </svg>
  );
}
