import React from 'react';
import Header from '../Header/Header';
import fontColors from '../../theme/fontColors';
import { makeStyles, Theme, Typography } from '@material-ui/core';
import ExpiredRoomIllustration from '../../icons/svg/ExpiredRoomIllustration.svg';

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    borderBottom: '1px solid #E0E0E0',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%',
    },
  },
  containerFeedback: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderTop: '1px solid #E0E0E0',
    height: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    [theme.breakpoints.down('sm')]: {
      borderTop: 'none',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      position: 'relative',
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  containerText: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '80px',
      width: '100% !important',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign: 'left',
    },
  },
  mainText: {
    fontSize: '32px',
    fontWeight: 700,
    width: '500px',
    color: fontColors.title,
    marginTop: '32px',
    marginBottom: '12px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      marginTop: '32px',
      marginBottom: '12px',
      width: 'auto',
    },
  },
  subText: {
    fontSize: '20px',
    fontWeight: 400,
    color: fontColors.subtitle,
    width: '500px',
    marginBottom: '48px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      width: '100%',
      lineHeight: '24px',
      margin: '0px',
    },
  },
  containerIllustration: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '80px',
    [theme.breakpoints.down('sm')]: {
      borderTop: 'none',
      paddingTop: '0',
      marginTop: '32px',
    },
  },
  illustration: {
    width: '391.56px',
    height: '340px',
    [theme.breakpoints.down('sm')]: {
      width: '230.33px',
      height: '200px',
    },
  },

  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
}));

const ExpiredRoom = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Header />
      <div className={classes.containerFeedback}>
        <div className={classes.containerIllustration}>
          <img className={classes.illustration} src={ExpiredRoomIllustration} alt="sala expirada" />
        </div>
        <div className={classes.containerText}>
          <Typography className={classes.mainText}>Sala expirada</Typography>
          <Typography className={classes.subText}>Infelizmente o tempo de consulta foi excedido.</Typography>
        </div>
      </div>
    </div>
  );
};

export default ExpiredRoom;
