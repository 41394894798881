import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import SirioLogo from '../../icons/SirioLogo';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      height: '88px',
      paddingLeft: '40px',
      zIndex: 1,
      borderBottom: '1px solid #E0E0E0',

      [theme.breakpoints.down('sm')]: {
        borderBottom: '1px solid #E0E0E0',
        width: '100%',
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },
  })
);

export default function Header() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <SirioLogo />
    </div>
  );
}
