import React from 'react';

export default function GaleryViewIcon() {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.00085 0.5C0.44857 0.5 0.000854492 0.947715 0.000854492 1.5V8.5C0.000854492 9.05228 0.44857 9.5 1.00085 9.5H8.00085C8.55314 9.5 9.00085 9.05228 9.00085 8.5V1.5C9.00085 0.947715 8.55314 0.5 8.00085 0.5H1.00085ZM2.00085 7.5V2.5H7.00085V7.5H2.00085ZM12.0009 0.5C11.4486 0.5 11.0009 0.947715 11.0009 1.5V8.5C11.0009 9.05228 11.4486 9.5 12.0009 9.5H19.0009C19.5531 9.5 20.0009 9.05228 20.0009 8.5V1.5C20.0009 0.947715 19.5531 0.5 19.0009 0.5H12.0009ZM13.0009 7.5V2.5H18.0009V7.5H13.0009ZM0.000854492 12.5C0.000854492 11.9477 0.44857 11.5 1.00085 11.5H8.00085C8.55314 11.5 9.00085 11.9477 9.00085 12.5V19.5C9.00085 20.0523 8.55314 20.5 8.00085 20.5H1.00085C0.44857 20.5 0.000854492 20.0523 0.000854492 19.5V12.5ZM2.00085 13.5V18.5H7.00085V13.5H2.00085ZM12.0009 11.5C11.4486 11.5 11.0009 11.9477 11.0009 12.5V19.5C11.0009 20.0523 11.4486 20.5 12.0009 20.5H19.0009C19.5531 20.5 20.0009 20.0523 20.0009 19.5V12.5C20.0009 11.9477 19.5531 11.5 19.0009 11.5H12.0009ZM13.0009 18.5V13.5H18.0009V18.5H13.0009Z"
        fill="white"
      />
    </svg>
  );
}
