import React from 'react';

export default function MicIcon() {
  return (
    <svg width="14" height="20" viewBox="0 0 14 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5 4C5 2.89543 5.89543 2 7 2C8.10457 2 9 2.89543 9 4V9C9 10.1046 8.10457 11 7 11C5.89543 11 5 10.1046 5 9H6C6.55228 9 7 8.55229 7 8C7 7.44772 6.55228 7 6 7H5V6H6C6.55228 6 7 5.55228 7 5C7 4.44772 6.55228 4 6 4H5ZM3 5V4C3 1.79086 4.79086 0 7 0C9.20914 0 11 1.79086 11 4V9C11 11.2091 9.20914 13 7 13C4.79086 13 3 11.2091 3 9V8V5ZM1 8C1.55228 8 2 8.44771 2 9C2 10.3261 2.52678 11.5979 3.46447 12.5355C4.40215 13.4732 5.67392 14 7 14C8.32608 14 9.59785 13.4732 10.5355 12.5355C11.4732 11.5979 12 10.3261 12 9C12 8.44771 12.4477 8 13 8C13.5523 8 14 8.44771 14 9C14 10.8565 13.2625 12.637 11.9497 13.9497C10.8744 15.0251 9.48522 15.7144 7.99749 15.9286C7.99915 15.9522 8 15.976 8 16V18H9C9.55228 18 10 18.4477 10 19C10 19.5523 9.55228 20 9 20H7H5C4.44772 20 4 19.5523 4 19C4 18.4477 4.44772 18 5 18H6V16C6 15.976 6.00085 15.9522 6.00251 15.9286C4.51478 15.7144 3.12559 15.0251 2.05025 13.9497C0.737498 12.637 0 10.8565 0 9C0 8.44771 0.447715 8 1 8Z"
        fill="white"
      />
    </svg>
  );
}
