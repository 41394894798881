import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider from './state';
// import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import theme from './theme';
import './types';

export const ReactApp = () => (
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <AppStateProvider>
      <App />
    </AppStateProvider>
  </MuiThemeProvider>
);

ReactDOM.render(<ReactApp />, document.getElementById('root'));
