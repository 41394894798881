import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useVideoContext from '../useVideoContext/useVideoContext';

type RoomStateType = 'disconnected' | 'connected' | 'reconnecting' | 'disconnecting';

export default function useRoomState() {
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomStateType>('disconnected');
  const location = useLocation();

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state as RoomStateType);
      setRoomState();
      room
        .on('disconnected', () => {
          setRoomState();

          window.location.assign(
            `${window.origin}${location.pathname.replace('atendimento', 'chamadaencerrada')}/${location.search}`
          );
        })
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState);

      return () => {
        room
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState);
      };
    }
  }, [room]);

  return state;
}
